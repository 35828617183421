<template>
    <div class="container-fluid">

    <div class="row mt-5">
        <div class="col-xl-11" style="margin:0 auto;">
            <div class="card shadow" >
             <p style="text-align:center;"><strong><h1>Bienvenido a Sai en linea {{nombre}}</h1></strong></p>
                <div class="form-group row" style="left-margin:10px;">
                        <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Empresa Seleccionada:</label>
                        <div class="col-md-8">
                            <div class="input-group">
                                <select class="form-control" v-on:change="datosemp(codempsifab)" v-model="codempsifab" id="codempsifab">
                                    <option  v-for="empresa in arrayEmpusu" :key="empresa.id" :value="empresa.codempsifab" v-text="empresa.nombreemp"></option>
                                </select>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
        </div>
    </div>

    </div>
</template>
<script>
export default {
        data(){
            return {
                nombre:'',
                codempsifab:0,
                arrayEmpusu:[]
            }
        },

        methods:{

            consu_cod(){
                let clase=this;
                var url='consu_cod';
                axios.get(url).then(function (response) {
                    clase.codempsifab=response.data.codempsifab;
                    clase.nombre=response.data.nombre;
                }).catch(function (error) {
                    console.log("error al consultar el codigo de la empresa");
                });
            },
            empresasUSu(){
                let clase=this;
                var url='/empresa_usu';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.consu_cod();
                    clase.arrayEmpusu=respuesta.empusuarios;



            }).catch(function (error) {
                    console.log('Error en consulta de selección de las empresas para este usuario');
                });

            },
            datosemp(codempsifab){
                let clase=this;
                var url='/datosemp?cod='+codempsifab;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    var codigo=respuesta.datosemp[0]['codempsifab'];
                    var nomemp=respuesta.datosemp[0]['nombre'];
                    var logo=respuesta.datosemp[0]['nombrelogo'];
                    var avatar = document.getElementById("avatar");
                    var avatar1 = document.getElementById("avatar1");
                    clase.nombre=nomemp;
                    clase.codempsifab=codigo;
                    avatar.setAttribute("src", "argon/img/brand/"+logo);
                    avatar1.setAttribute("src", "argon/img/brand/"+logo);
            }).catch(function (error) {
                    console.log('Error al seleccionar los datos de la empresa');
                });
            }
        },

            mounted() {
                this.empresasUSu();

        }
}
</script>
