
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import VueHtml2Canvas from "vue-html2canvas";

require('./bootstrap');

window.Vue = require('vue');
//Vue.use(require('vue-resource'));
//Vue.http.headers.common['X-CSRF-TOKEN'] = document.getElementById('csrf_token').value;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('registro_empresas', require('./components/RegistroEmpresas.vue').default);
Vue.component('registro_usuarios', require('./components/RegistroUsuarios.vue').default);
Vue.component('usuarios_empresas', require('./components/UsuariosRelEmpresas.vue').default);
Vue.component('cambiar_password_sai', require('./components/CambiarPassword.vue').default);
Vue.component('descargar_archivos', require('./components/DescargarArchivos.vue').default);
Vue.component('subir_archivos', require('./components/SubirArchivos.vue').default);
Vue.component('borrar_archivos', require('./components/borrarArchivo.vue').default);
Vue.component('listado_empresas', require('./components/listadoEmpresas.vue').default);
Vue.component('listado_usuarios', require('./components/listadoUsuarios.vue').default);
Vue.component('perfil', require('./components/perfil.vue').default);
Vue.component('Report_problema', require('./components/report_problema.vue').default);
Vue.component('login_sai', require('./components/loginSai.vue').default);
Vue.component('reset_password', require('./components/reset_password.vue').default);
Vue.component('ultimas_empresas', require('./components/ultimas_empresas.vue').default);
Vue.component('ultimos_usuarios', require('./components/ultimos_usuario.vue').default);



//componentes para empresas externas
Vue.component('escritorio_ext', require('./components/externa/Escritorio.vue').default);
Vue.component('descargar_archivos_ext', require('./components/externa/DescargarArchivos.vue').default);
Vue.component('cambiar_password_ext', require('./components/externa/CambiarPassword.vue').default);
Vue.component('informe', require('./components/externa/informe.vue').default);
Vue.component('Report_problema_ext', require('./components/externa/report_problema.vue').default);
Vue.component('login_ext', require('./components/externa/empresaext.vue').default);
Vue.component('reset_password_ext', require('./components/externa/reset_password_ext.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    data: {
        menu: 0,
        vermodal: 0
            }
});
