<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Empresas</li>
        </ol>
        <div class="container-fluid">
            <!-- Ejemplo de tabla Listado -->
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-align-justify"></i> Empresas
                    <button type="button" @click="abrirVentanaModal('agregar')" class="btn btn-secondary">
                        <i class="icon-plus"></i>&nbsp;Crear Nueva Empresa
                    </button>
                </div>
                <div class="card-body">
                    <div class=" form-group row">
                        <div class="col-md-5">
                            <div class="input-group">
                                <select class="form-control col-md-4" v-model="criterio">
                                    <option value="empresas.nombre">Nombre</option>
                                    <option value="codempsifab">Código SIFAB</option>
                                     <option value="contacto">Contacto</option>
                                </select>
                                <input type="text" v-model="buscar" @keyup.enter="listadoEmpresas(1,buscar,criterio)" class="form-control" placeholder="Texto a buscar">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button type="submit" @click="listadoEmpresas(1,buscar,criterio)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                        </div>
                        <div class="col-md-5">
                            <select class="form-control col-md-6" v-model="numpaginas" @change="listadoEmpresas(1,buscar,criterio,numpaginas)">  <!--  -->
                                            <option value="" selected>Registros por Página</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="30">30</option>
                                            <option value="35">35</option>
                                            <option value="40">40</option>
                                            <option value="45">45</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                        </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Código SIFAB</th>
                                <th>Nombre</th>
                                <th>Contacto</th>
                                <th>Estado</th>
                                 <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="empresa in arrayEmpresas" :key="empresa.id">
                                <td v-text="empresa.codempsifab"></td>
                                <td v-text="empresa.nombre"></td>
                                <td v-text="empresa.contacto"></td>
                                <td>
                                    <div v-if="empresa.estado=='A'">
                                        <span class="badge badge-success">Activo</span>
                                    </div>
                                        <div v-else>
                                        <span class="badge badge-danger">Inactivo</span>
                                    </div>
                                </td>
                                 <td>
                                    <button type="button" @click="abrirVentanaModal('actualizar',empresa)" data-toggle="tooltip" data-placement="top" title="Editar los datos de la empresa"
                                    class="btn btn-info btn-sm">
                                       <i class="fas fa-edit"></i>
                                    </button> &nbsp;
                                    <template v-if="empresa.estado=='A'">
                                        <button type="button" class="btn btn-danger btn-sm"  @click="inactivarEmpresa(empresa.id)" data-toggle="tooltip" data-placement="top" title="Inactivar Empresa">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button type="button" class="btn btn-success btn-sm"  @click="activarEmpresa(empresa.id)" data-toggle="tooltip" data-placement="top" title="Activar Empresa">
                                            <i class="fas fa-check"></i>
                                        </button>
                                    </template>

                                 </td>
                            </tr>
                        </tbody>
                      </table>
                      <div hidden style="text-align:center; font-weight: bold;" id="aviso">
                            <p><strong><h3>No existen datos para mostrar</h3></strong></p>
                        </div>
                    </div>
                        <nav style="text-align:center;">
                            <ul class="pagination" style="display: inline-flex; margin:0; padding:0;"> <!--centrar botones de navegación -->
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                                </li>

                                <li class="page-item" v-for="page in pagesNumber" :key="page.number" v-bind:class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                                </li>

                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                                </li>
                            </ul>
                        </nav>
                </div>
            </div>
            <!-- Fin ejemplo de tabla Listado -->
        </div>
        <!--Inicio del modal agregar/actualizar-->
        <div class="modal fade" tabindex="-1" :class="{'mostrar' : vermodal}" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog-scrollable.modal-dialog modal-primary modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" v-text="tituloVentana"></h4>
                        <button type="button" class="close" @click="cerrarVentanaModal()" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Código SIFAB</label>
                                <div class="col-md-9">
                                    <input type="text" v-model="codempsifab"  class="form-control" id="codempsifab" placeholder="Código SIFAB (Este codigo debe coincidir con el codigo de la empresa para la web del software SIFAB)" maxlength="10">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Nombre</label>
                                <div class="col-md-9">
                                    <input type="text" v-model="nombre" class="form-control" placeholder="Nombre de la Empresa">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Contacto</label>
                                <div class="col-md-9">
                                    <input type="email" v-model="contacto" class="form-control" placeholder="Ingrese Contacto">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Departamento</label>
                                <div class="col-md-3">
                                    <select class="form-control" v-model="coddepto" @change="filtrarMpios(coddepto)">
                                            <option value="0" disabled>Seleccione</option>
                                            <option v-for="depto in arrayDeptos" :key="depto.codigo" :value="depto.codigo" v-text="depto.nombre"></option>
                                    </select>
                                </div>
                                <label class="col-md-2 form-control-label" for="text-input" style="color:white;">Municipio</label>
                                <div class="col-md-4">
                                    <select class="form-control" v-model="idmpio">
                                        <option value="0" disabled>Seleccione</option>
                                        <option v-for="mpio in arrayMpiosDepto" :key="mpio.idmpio" :value="mpio.idmpio" v-text="mpio.nombre"></option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="email-input" style="color:white;">Sector Económico</label>
                                <div class="col-md-9">
                                    <select class="form-control" v-model="idsector">
                                            <option value="0" disabled>Seleccione</option>
                                            <option v-for="sector in arraySectores" :key="sector.id" :value="sector.id" v-text="sector.nombre"></option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 form-control-label" for="text-input" style="color:white;">Logo Empresa</label>
                                <div class="col-md-2" style="text-align:right">
                                    <img src="" id="logoemp" class="img-fluid" width="100" height="100"/>
                                    <br>
                                    <div class="upload-btn-wrapper" style="text-align:rig">
                                        <button class="btn btn-primary">Cambiar Logo</button>
                                        <input type="file" name="logo" width="200" height="200" @change="obtenerImagen" accept="image/*"/>
                                    </div>
                                </div>
                                <label class="col-md form-control-label" for="text-input" style="color:white;" hidden>Cantidad de Dias que estaran en linea los certificados</label>
                                <div class="col-md-2">
                                    <input type="number" v-model="dias" class="form-control" placeholder="Dias" hidden>
                                </div>
                            </div>
                             <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                    </div>
                                </div>
                            </div>

                        </form>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarVentanaModal()">Cerrar</button>
                            <button type="button" v-if="tipoAccion==1" class="btn btn-primary" @click="agregarEmpresa()">Guardar</button>
                            <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizarEmpresa()">Actualizar</button>
                        </div>
                    </div>

                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!--Fin del modal-->
</main>
</template>

<script>
    export default {
        data(){
            return {
                idempresa : 0,    //para actualizar una empresa
                codempsifab: '',
                nombre :'',
                contacto :'',
                vermodal : 0,   //para controlar si se ve la ventana modal
                tituloVentana : '',
                tipoAccion : 0,  //para saber si actualiza o graba un nuevo registro
                errores : 0,    //para saber si hay errores en la validación
                mensajeErrores : [],
                pagination: {
                    'total':0,
                    'current_page':0,
                    'per_page':0,
                    'last_page':0,
                    'from':0,
                    'to':0
                },
                offset:3,  //para la paginación
                criterio: 'empresas.nombre',
                buscar: '',
                numpaginas: '',   //por defecto 5 registros por página
                arrayEmpresas: [],
                arraySectores: [],   //sectores económicos
                arrayDeptos: [],
                arrayMpios: [],
                arrayMpiosDepto: [],
                arrayEmpresasNoPaginadas: [],
                idsector : 0,  //para agregar el sector económico de la empresa
                coddepto: 0,
                idmpio: 0,
                dias:0,
                logoemp:null,
                nombrelogo:'',
                titulo:'',
                logoant:'',
                fecactu:'',
            }
        },
        //las propiedades computadas son cacheadas basándose en sus dependencias. Una propiedad computada solo se reevaluará
        //cuando alguna de sus dependencias haya cambiado. Esto significa que mientras no haya cambiado, acceder reiteradamente
        //a la propiedad computada devolverá inmediatamente el resultado computado previamente sin tener que ejecutar la
        //función de nuevo. las propiedades computadas se usan generalmente para calculos complejos y renderizan más rápido
        //que las propiedades o métodos normales. ver artículo.
        //https://es-vuejs.github.io/vuejs.org/v2/guide/computed.html
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            pagesNumber: function(){
                if(!this.pagination.to){
                    return [];
                }
                var from = this.pagination.current_page - this.offset;
                if(from < 1){
                    from = 1;
                }
                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to= this.pagination.last_page;
                }
                var pagesArray = [];
                while(from <= to){
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            }
        },
        methods: {
            mostraralerta(){
                alert('evento');  //para observar la ejecucuín de eventos
            },
            listadoEmpresas(page,textobuscar,condicion,pnumpaginas=5){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresas?page='+ page + '&buscar=' + textobuscar + '&criterio=' + condicion + '&numpaginas=' + pnumpaginas;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    clase.arrayEmpresas=response.data.empresas.data;
                    clase.pagination=response.data.pagination;
                    if(clase.arrayEmpresas.length==0){
                        document.getElementById('aviso').removeAttribute('hidden');
                   }else{
                       document.getElementById('aviso').setAttribute('hidden', 'hidden');
                   }
                    //console.log(response);
                })
                .catch(function (error) {
                    console.log('Error en Consulta de Empresas por Página');
                });
            },

            agregarEmpresa(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                if(this.validarEmpresa()){
                    return; //SI HAY ERRORES RETORNA
                }
                var data = new FormData();
                    data.append('codempsifab',this.codempsifab);
                    data.append('nombre',this.nombre);
                    data.append('contacto',this.contacto);
                    data.append('mpio',this.idmpio);
                    data.append('sector',this.idsector);
                    data.append('titulo',this.titulo);
                    data.append('logo',this.logoemp);
                    data.append('dias',this.dias);
                    data.append('_method','post');
                    axios.post('/empresas/agregar',data)
                .then(response=>{
                    //se ejecuta de forma satisfactoria
                    clase.cerrarVentanaModal();
                    clase.listadoEmpresas(1,'','nombre');
                })
                .catch(function (error) {
                     //alert ('entro aqui al error');
                    console.log(error);
                });
            },
            actualizarEmpresa(){
                if(this.validarEmpresa()){
                    return; //SI HAY ERRORES RETORNA
                }
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var data = new FormData();
                //console.log(this.idempresa);
                    data.append('id',this.idempresa);
                    data.append('codempsifab',this.codempsifab);
                    data.append('nombre',this.nombre);
                    data.append('contacto',this.contacto);
                    data.append('mpio',this.idmpio);
                    data.append('sector',this.idsector);
                    data.append('titulo',this.titulo);
                    data.append('logo',this.logoemp);
                    data.append('dias',this.dias);
                    data.append('logoant',this.logoant);
                    data.append('fecactu',this.fecactu);
                    data.append('_method','post');
                    axios.post('/empresas/actualizar',data)
                .then(response=>{  //el verbo post envía los datos
                    //se ejecuta de forma satisfactoria
                    clase.cerrarVentanaModal();
                    clase.listadoEmpresas(1,'','nombre');
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            validarEmpresa(){
                this.errores=0;
                this.mensajeErrores=[];
                if(!this.nombre)this.mensajeErrores.push("Debe Ingresar el Nombre de la Empresa");
                if(!this.codempsifab)this.mensajeErrores.push("Debe Ingresar el Código de la Empresa como está en SIFAB");
                if(this.codempsifab.length>10)this.mensajeErrores.push("El codigo de la empresa  puede tener maximo 10 caracteres");
                if(!this.coddepto)this.mensajeErrores.push("Debe Seleccionar el Departamento");
                if(!this.idmpio)this.mensajeErrores.push("Debe Seleccionar el Municipio");
                if(!this.idsector)this.mensajeErrores.push("Debe Seleccionar el Sector Económico");
                if(this.tipoAccion==1){ //solo cuando se esté insertando
                    if(this.buscarCodigoSifab(this.codempsifab)){
                        this.mensajeErrores.push("El Código SIFAB de la empresa no se puede repetir");
                    }
                }
                //si existen errores hasta aquí, retorne
                if(this.mensajeErrores.length) this.errores=1;

                return this.errores;
            },
            buscarCodigoSifab(pcodigosifab){
                //este metodo es para recorrer el array y actualizar un valor en la llave de a empresa relacionada
                //pvalor es A=Activo  I=Inactivo
                //no se carga toda la consulta. se recorre el arreglo, se encuentra el dato y se actualiza para cambiar
                //dinamicamente la vista. se hace sobre el arreglo de todos los usuarios
                let bandera=0;
                this.arrayEmpresasNoPaginadas.forEach(function(element) {
                if (element['codempsifab']===pcodigosifab){
                       bandera=1;   //encontró el codigo. no se puede crear la empresa con ese código
                       //aquí si se hace el return directamente como para interrumpir el foreach no funciona
                       //el retorno correctamente. por eso se hace con una bandera. si se hace aqui el return siempre retorna cero
                    }
                });
                return bandera;
            },
            cerrarVentanaModal(){
                this.vermodal=0;
                this.codempsifab='';
                this.nombre='';
                this.contacto ='';
                this.tituloVentana='';
                this.idsector=0;
                this.coddepto=0;
                this.idmpio=0;
                this.dias=0;
                this.logoemp=null;
                this.arrayMpiosDepto=[];    //vaciar el arreglo filtrado par que no aparezca nada
                this.mensajeErrores=[];
                document.getElementById("logoemp").setAttribute("src", "");
            },
            abrirVentanaModal(accion, data=[]){
               switch(accion){
                    case 'agregar':
                        this.vermodal=1;
                        this.codempsifab='';
                        this.nombre='';
                        this.contacto ='';
                        this.dias=0;
                        this.logo=null;
                        this.tituloVentana='Agregar Empresa';
                        this.tipoAccion=1;
                        document.getElementById('codempsifab').removeAttribute('readonly'); //quitar readonly
                        break;
                    case 'actualizar' :
                        this.vermodal=1;
                        this.tituloVentana='Actualizar Empresa';
                        this.tipoAccion=2;
                        //trear los datos vinculados para mostrar los valores que ya tiene el registro de la tabla
                        this.idempresa=data.id;
                        this.codempsifab=data.codempsifab;
                        this.nombre=data.nombre;
                        this.contacto =data.contacto;
                        this.idsector=data.idsector;
                        this.coddepto=data.coddepto
                        this.filtrarMpios(this.coddepto);
                        this.idmpio=data.idmpio;
                        this.dias=data.dias_archi;
                        this.nombrelogo=data.nombrelogo;
                        this.logoemp=data.logoemp;
                        this.logoant=data.nombrelogo;
                        var logo = document.getElementById("logoemp");
                        logo.setAttribute("src", "argon/img/brand/"+this.nombrelogo);
                        document.getElementById('codempsifab').setAttribute('readonly', 'readonly'); //colocar readonly
                        break;
                }
            },
            inactivarEmpresa(pidempresa){
                swal({
                title: 'Esta seguro de Inactivar esta Empresa?',
                text: "",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar!',
                cancelButtonText: 'Cancelar',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
                }).then((result) => {
                if (result.value) {
                    let clase=this; //declaramos una variable para recibir toda la data del objeto response
                    axios.put('/empresas/inactivar',{
                        'id' : pidempresa  //id de la empresa a actualizar. ingresa como parámetro
                    }).then(function (response) {  //el verbo post envía los datos
                        //se ejecuta de forma satisfactoria
                       clase.listadoEmpresas(1,'','nombre');
                        swal(
                        'Inactivada!',
                        'La Empresa se ha inactivado correctamente',
                        'success'
                        )
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                } else if (
                    // Read more about handling dismissals
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    //no mostrar mensaje si el usuario cancela
                    /* swal(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                    ) */
                }
                })
            },
            activarEmpresa(pidempresa){
                swal({
                title: 'Esta seguro de Activar esta Empresa?',
                text: "",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar!',
                cancelButtonText: 'Cancelar',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
                }).then((result) => {
                if (result.value) {
                    let clase=this; //declaramos una variable para recibir toda la data del objeto response
                    axios.put('/empresas/activar',{
                        'id' : pidempresa  //id de la empresa a actualizar. ingresa como parámetro
                    }).then(function (response) {  //el verbo post envía los datos
                        //se ejecuta de forma satisfactoria
                       clase.listadoEmpresas(1,'','nombre');
                        swal(
                        'Activada!',
                        'La Empresa se ha activado correctamente',
                        'success'
                        )
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                } else if (
                    // Read more about handling dismissals
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    //no mostrar mensaje si el usuario cancela
                    /* swal(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                    ) */
                }
                })
            },

            cambiarPagina(page,textobuscar,condicion){
                this.pagination.current_page = page;
                //envia la petición para visualizar la data de esa página
                this.listadoEmpresas(page,textobuscar,condicion);
            },
            seleccionarSectores(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresas/listadosectores';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.arraySectores=respuesta.sectores;
                })
                .catch(function (error) {
                    console.log('Error en consulta de selección de Sectores Económicos');
                });
            },
            seleccionarDeptos(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresas/listadodeptos';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.arrayDeptos=respuesta.deptos;
                })
                .catch(function (error) {
                    console.log('Error en consulta de selección de Departamentos');
                });
            },
            seleccionarEmpresas(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresas/listadoempresas';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.arrayEmpresasNoPaginadas=respuesta.empresas;
                   // console.log("empresas no paginadas");
                })
                .catch(function (error) {
                    console.log('Error en consulta de selección de Empresas');
                });
            },

             seleccionarMpios(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresas/listadompios';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    //var respuesta=response.data;
                    clase.arrayMpios=response.data.mpios;
                   // console.log('x');
                })
                .catch(function (error) {
                    console.log('Error en consulta de selección de Municipios');
                });
            },
            filtrarMpios(pcoddepto)
            {
               //se hace esta función para que al cambiar el depto de carguen solo los municipios de ese depto.
               //se habia hecho de otra forma para que siempre se hiciera una petición al controlador y retornara
               //los datos ya filtrados. sin mebargo, pudimos evidenciar que con arreglos es mas rápido.
               //en un array se cargan todos los municipios y en el otro se filtran los que sena solo del depto.
               let clase=this;
               clase.arrayMpiosDepto =  clase.arrayMpios.filter(function(mpio) {
	                return mpio.coddepto == pcoddepto;
                });
                //alert('ya llenó el como mpios filtrado');
            },
            validarArchivo(){
                this.errores=0;
                this.mensajeErrores=[];
                if(this.logoemp.size>2000000)this.mensajeErrores.push("La imagen supera el tamaño maximo permitido");
                if(this.mensajeErrores.length) this.errores=1;
                return this.errores;

            },
            obtenerImagen(event){
               this.logoemp = event.target.files[0];
               var exten=this.logoemp.type.substring(6);
               //console.log(exten);
               if(this.validarArchivo()){
                   this.logoemp='';
                    return; //SI HAY ERRORES RETORNA
               }
               var titulo=this.codempsifab.split(".",1);
               this.titulo=titulo[0]+'.'+exten;
               console.log(this.titulo);
               const objectURL = URL.createObjectURL(this.logoemp);
               var logoemp = document.getElementById("logoemp");
               logoemp.setAttribute("src", objectURL);
            },
        },
        created(){
            //cargar datos de combos. Es mucho mas eficiente aquí que en el abrirmodal ya que en abrirmodal siempre se debe hacer la peticion get al controlador
            //para llenar los registros. aquí se hace cuando se inicia el componente. es como el evento init de un formulario.


        },
        mounted() {
            this.listadoEmpresas(1,this.buscar,this.criterio)
            this.seleccionarDeptos();
            this.seleccionarMpios();
            this.seleccionarSectores(); //cargar sectores económicos
            this.seleccionarEmpresas();  //arreglo para validar si el codigo sifab ya existe en alguna empresa
        }
    }
</script>

<style>
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
    }
    .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }

</style>
