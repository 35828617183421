<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Listado Usuarios</li>
        </ol>
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-align-justify"></i> Listado Usuarios
                </div>

                <div class="card-body">
                <button type="submit" @click.prevent="exportExcel()" class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Descargar listado en Excel"
                ><i class="fa fa-file-excel"></i>Listado en Excel</button>
                     <div class="table-responsive">
                      <table class="table table-bordered table-striped table-sm">
                        <thead>
                                <tr>
                                    <th >Empresa</th>
                                    <th>Usuario</th>
                                    <th>Nombre</th>
                                    <th>Cargo</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="usuario in arrayUsuarios" :key="usuario.id">
                                    <td v-text="usuario.nombreemp"></td>
                                    <td v-text="usuario.email"></td>
                                    <td v-text="usuario.nombre"></td>
                                    <td v-text="usuario.cargo"></td>
                                    <td>
                                        <div v-if="usuario.estado=='A'">
                                            <span class="badge badge-success">Activo</span>
                                        </div>
                                            <div v-else>
                                            <span class="badge badge-danger">Inactivo</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                      </table>
                    <div hidden style="text-align:center;" id="aviso">
                         <p><strong><h3>No existen datos para mostrar</h3></strong></p>
                    </div>
                    </div>
                    <nav style="text-align:center;">
                            <ul class="pagination" style="display: inline-flex; margin:0; padding:0;"> <!--centrar botones de navegación -->
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                                </li>

                                <li class="page-item" v-for="page in pagesNumber" :key="page.number" v-bind:class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                                </li>

                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                                </li>
                            </ul>
                        </nav>

                </div>
            </div>

        </div>
    </main>
</template>
<script>
export default {
        data(){
            return {

                arrayUsuarios:[],
                arrayUsuariostodos:[],
                    pagination: {
                    'total':0,
                    'current_page':0,
                    'per_page':0,
                    'last_page':0,
                    'from':0,
                    'to':0
                },
                offset:3,  //para la paginación
                criterio:'useremp.nombre',
                buscar:'',
                numpaginas: '',   //por defecto 6 registros por página


            }
        },
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            pagesNumber: function(){
                if(!this.pagination.to){
                    return [];
                }
                var from = this.pagination.current_page - this.offset;
                if(from < 1){
                    from = 1;
                }
                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to= this.pagination.last_page;
                }
                var pagesArray = [];
                while(from <= to){
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            }
        },
        methods:{
            listadoUsuarios(page,textobuscar,condicion,pnumpaginas=5){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/usuarios?page='+ page + '&buscar=' + textobuscar + '&criterio=' + condicion + '&numpaginas=' + pnumpaginas;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    clase.arrayUsuarios=response.data.usuariosemp.data;
                    clase.pagination=response.data.pagination;
                    if(clase.arrayUsuarios.length==0){
                        document.getElementById('aviso').removeAttribute('hidden');
                   }else{
                       document.getElementById('aviso').setAttribute('hidden', 'hidden');
                   }
                })
                .catch(function (error) {
                    console.log('Error en Consulta de Empresas por Página');
                });
            },
            todosusuarios(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/usuarios/todosusuarios';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                //console.log(response.data)
                    var respuesta=response.data;
                    clase.arrayUsuariostodos=respuesta.usuariosemp;
                })
                .catch(function (error) {
                    console.log('Error en consulta de selección de todas las empresas');
                });
            },
            cambiarPagina(page,textobuscar,condicion){
                this.pagination.current_page = page;
                //envia la petición para visualizar la data de esa página
                this.listadoUsuarios(page,textobuscar,condicion);
            },
            exportExcel: function () {
                let data = XLSX.utils.json_to_sheet(this.arrayUsuariostodos)
                const workbook = XLSX.utils.book_new()
                const filename = 'Usuarios'
                XLSX.utils.book_append_sheet(workbook, data, filename)
                XLSX.writeFile(workbook, `${filename}.xlsx`)
    }
        },

            mounted() {
           this.listadoUsuarios(1,this.buscar,this.criterio);
           this.todosusuarios();
        }
}
</script>
