<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Listado Empresas</li>
        </ol>
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-align-justify"></i> Listado Empresas
                </div>

                <div class="card-body">
                <button type="submit" @click.prevent="exportExcel()" class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Descargar listado en Excel"
                ><i class="fa fa-file-excel"></i>Listado en Excel</button>
                     <div class="table-responsive">
                      <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Código SIFAB</th>
                                <th>Nombre</th>
                                <th>Contacto</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="empresa in arrayEmpresas" :key="empresa.id">
                                <td v-text="empresa.codempsifab"></td>
                                <td v-text="empresa.nombre"></td>
                                <td v-text="empresa.contacto"></td>
                                <td>
                                    <div v-if="empresa.estado=='A'">
                                        <span class="badge badge-success">Activo</span>
                                    </div>
                                        <div v-else>
                                        <span class="badge badge-danger">Inactivo</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                      <div hidden style="text-align:center; font-weight: bold;" id="aviso">
                            <p><strong><h3>No existen datos para mostrar</h3></strong></p>
                        </div>
                    </div>
                    <nav style="text-align:center;">
                            <ul class="pagination" style="display: inline-flex; margin:0; padding:0;"> <!--centrar botones de navegación -->
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                                </li>

                                <li class="page-item" v-for="page in pagesNumber" :key="page.number" v-bind:class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                                </li>

                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                                </li>
                            </ul>
                        </nav>

                </div>
            </div>

        </div>
    </main>
</template>
<script>
export default {
        data(){
            return {

                arrayEmpresas:[],
                arrayEmpresastodas:[],
                    pagination: {
                    'total':0,
                    'current_page':0,
                    'per_page':0,
                    'last_page':0,
                    'from':0,
                    'to':0
                },
                offset:3,  //para la paginación
                criterio:'empresas.nombre',
                buscar:'',
                numpaginas: '',   //por defecto 6 registros por página


            }
        },
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            pagesNumber: function(){
                if(!this.pagination.to){
                    return [];
                }
                var from = this.pagination.current_page - this.offset;
                if(from < 1){
                    from = 1;
                }
                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to= this.pagination.last_page;
                }
                var pagesArray = [];
                while(from <= to){
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            }
        },
        methods:{
            listadoEmpresas(page,textobuscar,condicion,pnumpaginas=5){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresas?page='+ page + '&buscar=' + textobuscar + '&criterio=' + condicion + '&numpaginas=' + pnumpaginas;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    clase.arrayEmpresas=response.data.empresas.data;
                    clase.pagination=response.data.pagination;
                    if(clase.arrayEmpresas.length==0){
                        document.getElementById('aviso').removeAttribute('hidden');
                   }else{
                       //console.log('entro al else');
                       document.getElementById('aviso').setAttribute('hidden', 'hidden');
                   }
                    //console.log(response);
                })
                .catch(function (error) {
                    console.log('Error en Consulta de Empresas por Página');
                });
            },
            todasempresas(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresas/todasempresas';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.arrayEmpresastodas=respuesta.empresas;
                })
                .catch(function (error) {
                    console.log('Error en consulta de selección de todas las empresas');
                });
            },
            cambiarPagina(page,textobuscar,condicion){
                this.pagination.current_page = page;
                //envia la petición para visualizar la data de esa página
                this.listadoEmpresas(page,textobuscar,condicion);
            },
            exportExcel: function () {
                let data = XLSX.utils.json_to_sheet(this.arrayEmpresastodas)
                const workbook = XLSX.utils.book_new()
                const filename = 'Listado de Empresas'
                XLSX.utils.book_append_sheet(workbook, data, filename)
                XLSX.writeFile(workbook, `${filename}.xlsx`)
    }
        },

            mounted() {
           this.listadoEmpresas(1,this.buscar,this.criterio);
           this.todasempresas();
        }
}
</script>
