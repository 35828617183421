<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Borrar Archivos</li>
        </ol>
        <div class="container-fluid">
            <!-- Ejemplo de tabla Listado -->
            <div class="card">

                <div class="card-body">
                    <div class="form-group row">
                        <label class="col-md-2 form-control-label" for="text-input" style="color:Black;">Empresa</label>
                        <div class="col-md-10">
                             <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                    </div>
                                </div>
                            </div>
                            <div class="input-group" >
                                <select class="form-control" v-model="codempsifab" id="codempsifab">
                                    <option value="0" disabled>Seleccione la Empresa</option>
                                    <option v-for="empresa in arrayEmpresas" :key="empresa.id" :value="empresa.codempsifab" v-text="empresa.nombre"></option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Tipo de Archivo</label>
                        <div class="col-md-4">
                            <select class="form-control" v-model="tipoarchi">
                                <option value="" selected>Elegir Tipo de Archivo</option>
                                <option value="CT">Certificados</option>
                                <option value="HC">Historia Clínica</option>
                                <option value="FT">Facturas</option>
                                <option value="99">Todos</option>
                            </select>
                        </div>
                        <button type="submit" @click="listadoArchivos(codempsifab,tipoarchi,documento,fechaini,fechafin)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                    </div>
                </div>

                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th >Fecha</th>
                                    <th>Documento </th>
                                    <th>Nombres</th>
                                    <th>Tipo</th>
                                    <th>

                                        <button type="submit" @click="eliminarArchivo(codempsifab,'*',1)" class="btn btn-danger"><i class="fas fa-trash-alt"></i> Borrar Todos</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="archivo in arrayArchivos" :key="archivo.id">
                                    <td v-text="separar_Nombre_Archivo('fecha','certificado',archivo)"></td>
                                    <td v-text="separar_Nombre_Archivo('documento','certificado',archivo)"></td>
                                    <td v-text="separar_Nombre_Archivo('nombre','certificado',archivo)"></td>
                                    <td v-text="separar_Nombre_Archivo('tipoexam','certificado',archivo)"></td>
                                    <td>
                                        <div>
                                          <!--  <a v-bind:href="archivo" class="btn btn-link"><i class="fa fa-download"></i>Descargar</a>  -->


                                          <button type="submit" @click="eliminarArchivo(codempsifab,archivo,2)" class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Eliminar Archivo"
                                          ><i class="fas fa-trash-alt"></i></button>






                                           <!-- <span class="badge badge-success">Descargar</span></a>  -->
                                        </div>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <div hidden style="text-align:center; font-weight: bold;" id="aviso">
                            <p><strong><h3>No existen datos para mostrar</h3></strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Fin ejemplo de tabla Listado -->

 </main>
</template>

<script>
import { timeout } from 'q';
import { setTimeout } from 'timers';
    export default {
        data(){
            return {
                codempsifab : 0,
                tipoarchi: '',
                documento: '',
                fechaini: '',
                fechafin: '',
                arrayArchivos: [],
                arrayEmpresas:[],
                archivop:'',
                errores: 0,
                mensajeErrores:[]
            }
        },

        methods: {
            mostraralerta(){
                alert('evento');  //para observar la ejecucuín de eventos
            },
             listadoArchivos(pcodempsifab,ptipoarchi,pdocumento,pfechaini,pfechafin){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                if(this.validarDatos()==1)return;

                var url='/empresas/listado_archivos?codempsifab='+ pcodempsifab + '&tipoarchi=' + ptipoarchi + '&documento=' +
                        pdocumento + '&fechaini=' + pfechaini + '&fechafin=' + pfechafin;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                   clase.arrayArchivos=response.data;
                   if(clase.arrayArchivos.length==0){
                        document.getElementById('aviso').removeAttribute('hidden');
                   }else{
                       //console.log('entro al else');
                       document.getElementById('aviso').setAttribute('hidden', 'hidden');
                   }
                   //console.log(response);
                })
                .catch(function (error) {
                    console.log('Error en Listado de archivos');
                });
            },
            listadoEmpresas(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.get('/usuarios_empresa').then(function (response) {  //el verbo get trae los datos
                    clase.arrayEmpresas=response.data;
                   // clase.arrayArchivos=response.data;

                    //console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            validarDatos(){
                this.errores=0;
                this.mensajeErrores=[];
                if(!this.codempsifab)this.mensajeErrores.push("Debe Seleccionar la Empresa");
                if(!this.tipoarchi)this.mensajeErrores.push("Debe Seleccionar el tipo de Archivo");
                //si existen errores hasta aquí, retorne
                if(this.mensajeErrores.length) this.errores=1;

                return this.errores;
            },
            //función para separar el nombre del archivo y colocar el nombre en la tabla de una forma mejor presentada
            separar_Nombre_Archivo(xparte,xtipoarchi,xnomarchi)
            {
                //el formato del nombre es:
                //tipoarchivo_tipoexamen(3)_fecha(8)_documento_nombres.pdf
                //substring de javascript toma encuenta el inicio y no los caracteres a extraer sino la posicion final.
                //asi un substring(10,15) extrae 5 caracteres desde la posición 10 hasta la 15.
                var xcadena,xdia,xmes,xano,xinicio,xfin,xrestocar,i;
                xcadena="";
                if(xtipoarchi.toUpperCase()=='CERTIFICADO'){
                    if(xparte=='tipoexam'){
                        xcadena=xnomarchi.substring(12,15);
                        return xcadena;
                    }
                    if(xparte=='fecha'){
                                                                    //16 EL GUION
                        xdia=xnomarchi.substring(16,18);//CERTIFICADO_ING_02012018_1010109110_LUISA_MARIA_MARTINEZ_HOLGUIN
                        xmes=xnomarchi.substring(18,20);
                        xano=xnomarchi.substring(20,24);
                        xcadena=xdia + '/' + xmes + '/' + xano;
                        return xcadena;
                    }
                    if(xparte=='documento')
                    {
                        //aqui se inicia en el punto del numero de documento y se vá hasta que encuentre el caracter _
                        xinicio=25;

                        xrestocar=xnomarchi.length - xinicio;
                    // console.log('longitud= ' + xnomarchi.length);
                    // console.log('restocar=longi-25 = ' + xrestocar);

                        xfin=xrestocar;  //inicializar
                        for(i=1; i<=xrestocar; i++){
                        //    console.log(xnomarchi.substring(xinicio+i,xinicio+i+1));
                            if(xnomarchi.substring(xinicio+i,xinicio+i+1)=='_'){
                                xfin=i;
                                break;  //salir del ciclo
                            }
                        }
                        xcadena=xnomarchi.substring(xinicio,xinicio + xfin);
                        return xcadena;
                    }
                    if(xparte=='nombre')
                    {
                        //aqui se inicia en el punto del numero de documento y se vá hasta que encuentre el caracter _,
                        //luego se va desde ese punto hasta el final
                        xinicio=25;
                        xrestocar=xnomarchi.length -xinicio;
                        xfin=xrestocar;  //inicializar
                        for(i=1; i<=xrestocar; i++){
                            if(xnomarchi.substring(xinicio+i,xinicio+i+1)=='_'){
                                xfin=i;
                                break;  //salir del ciclo
                            }
                        }
                        xcadena=xnomarchi.substring(xinicio+xfin,xnomarchi.length-4); //el menos 5 recorta la extensión .pdf
                        xcadena=xcadena.replace(/_/g, " ");  //busca el _ y lo reemplza con un espacio
                        return xcadena;
                    }
                }
            },
            eliminarArchivo(pcodempsifab,parchivo,ptipo){
            let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.get('/eliminarArchivos?&archivo='+parchivo+'&codempsifab='+pcodempsifab+'&tipo='+ptipo).then(function (response) {  //el verbo get trae los datos
            if(response.data==1){
                 swal({
                    title: 'Los Archivos se han eliminado correctamente',
                    text: "",
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar!'
                    })

            }else{
                swal({
                    title: 'no se han podido Eliminar los archivos porfavor revise los datos',
                    text: "",
                    type: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar!'
                    })
            }
            clase.listadoArchivos(pcodempsifab,'CT','','','');
            })
            },
        },

        mounted() {
           this.listadoEmpresas();
        }
    }
</script>

<style>

        .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }
</style>
