<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Descargar Archivos</li>
        </ol>
        <div class="container-fluid">
            <p style="text-align:center;"></p>
            <!-- Ejemplo de tabla Listado -->
            <div class="card">
                <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                    </div>
                                </div>
                            </div>
                            <div style="text-align:center;">
                                <p>Empresa Seleccionada Actualmente: <strong>{{nombre}}</strong></p>
                            </div>
                <div class="form-group row">
                        <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Tipo de Archivo</label>

                        <div class="col-md-4">
                            <select class="form-control" v-model="tipoarchi">
                                <option value="" selected>Elegir Tipo de Archivo</option>
                                <option value="CT">Certificados</option>
                                <option value="HC">Historia Clínica</option>
                                <option value="FT">Facturas</option>
                                <option value="99">Todos</option>
                            </select>
                        </div>
                    <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Documento</label>
                    <div class="col-md-4">
                        <input type="text"  class="form-control" placeholder="Documento" v-model="documento">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Fecha Inicial</label>
                    <div class="col-md-3">
                        <input type="date"  class="form-control" v-model="fechaini">
                    </div>
                    <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Fecha Final</label>
                    <div class="col-md-3">
                        <input type="date"  class="form-control" v-model="fechafin">
                    </div>
                <button type="submit" @click="listadoArchivos(tipoarchi,documento,fechaini,fechafin)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                </div>

                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th >Fecha</th>
                                    <th>Documento </th>
                                    <th>Nombres</th>
                                    <th>Tipo</th>
                                    <th>
                                        <button type="submit" @click="descargarArchivoZip(codempsifab,colocarNombreArchivo(),2,tipoarchi,documento,fechaini,fechafin)" class="btn btn-danger"><i class="fa fa-download"></i> Des.Todos</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="archivo in arrayArchivos" :key="archivo.id">
                                    <td v-text="separar_Nombre_Archivo('fecha','certificado',archivo)"></td>
                                    <td v-text="separar_Nombre_Archivo('documento','certificado',archivo)"></td>
                                    <td v-text="separar_Nombre_Archivo('nombre','certificado',archivo)"></td>
                                    <td v-text="separar_Nombre_Archivo('tipoexam','certificado',archivo)"></td>
                                    <td>
                                        <div>
                                          <!--  <a v-bind:href="archivo" class="btn btn-link"><i class="fa fa-download"></i>Descargar</a>  -->


                                          <button type="submit" @click="descargarArchivo(archivo,1)" class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Descargar"
                                          ><i class="fa fa-download"></i></button>

                                          <button target="_blank" @click="verArchivo(archivo)" class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Ver archivo"
                                          ><i class="fa fa-eye"></i></button>

                                         <!-- <button type="submit" @click="verArchivo(codempsifab,archivo)" class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Ver Archivo"
                                          ><i class="fa fa-eye"></i></button>-->




                                           <!-- <span class="badge badge-success">Descargar</span></a>  -->
                                        </div>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <div hidden style="text-align:center; font-weight: bold;" id="aviso">
                            <p><strong><h3>No existen datos para mostrar</h3></strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Fin ejemplo de tabla Listado -->

 </main>
</template>

<script>
import { timeout } from 'q';
import { setTimeout } from 'timers';
    export default {
        data(){
            return {
                tipoarchi: '',
                documento: '',
                fechaini: '',
                fechafin: '',
                arrayArchivos: [],
                archivop:'',
                errores: 0,
                mensajeErrores:[],
                nombre:''
            }
        },

        methods: {
            colocarNombreArchivo(){
                var hoy = new Date();
                var dd = hoy.getDate();
                var mm = hoy.getMonth()+1;
                var yyyy = hoy.getFullYear();
                if(dd<10) {
                    dd='0'+dd;
                }
                if(mm<10) {
                    mm='0'+mm;
                }
                var nomarchi='archivos_' + this.codempsifab + '_' +  dd + '_' + mm + '_' + yyyy + '.zip';
                return nomarchi;
            },
             listadoArchivos(tipoarchi,pdocumento,pfechaini,pfechafin){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                if(this.validarDatos()==1)return;

                var url='/useremp/listadoArchivos?tipoarchi=' + this.tipoarchi + '&documento=' +
                        pdocumento + '&fechaini=' + pfechaini + '&fechafin=' + pfechafin;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                   clase.arrayArchivos=response.data;
                   if(clase.arrayArchivos.length==0){
                        document.getElementById('aviso').removeAttribute('hidden');
                   }else{
                       document.getElementById('aviso').setAttribute('hidden', 'hidden');
                   }
                   //console.log(response);
                })
                .catch(function (error) {
                    console.log('Error en Listado de archivos');
                });
            },
            validarDatos(){
                this.errores=0;
                this.mensajeErrores=[];
                if(!this.tipoarchi)this.mensajeErrores.push("Debe Seleccionar el tipo de Archivo");
                //si existen errores hasta aquí, retorne
                if(this.mensajeErrores.length) this.errores=1;

                return this.errores;
            },
            //función para separar el nombre del archivo y colocar el nombre en la tabla de una forma mejor presentada
            separar_Nombre_Archivo(xparte,xtipoarchi,xnomarchi)
            {
                //el formato del nombre es:
                //tipoarchivo_tipoexamen(3)_fecha(8)_documento_nombres.pdf
                //substring de javascript ta encuenta el inicio y no los caracteres a extraer sino la posicion final.
                //asi un substring(10,15) extrae 5 caracteres desde la posición 10 hasta la 15.
                var xcadena,xdia,xmes,xano,xinicio,xfin,xrestocar,i;
                xcadena="";
                if(xtipoarchi.toUpperCase()=='CERTIFICADO'){
                    if(xparte=='tipoexam'){
                        xcadena=xnomarchi.substring(12,15);
                        return xcadena;
                    }
                    if(xparte=='fecha'){
                                                                    //16 EL GUION
                        xdia=xnomarchi.substring(16,18);//CERTIFICADO_ING_02012018_1010109110_LUISA_MARIA_MARTINEZ_HOLGUIN
                        xmes=xnomarchi.substring(18,20);
                        xano=xnomarchi.substring(20,24);
                        xcadena=xdia + '/' + xmes + '/' + xano;
                        return xcadena;
                    }
                    if(xparte=='documento')
                    {
                        //aqui se inicia en el punto del numero de documento y se vá hasta que encuentre el caracter _
                        xinicio=25;

                        xrestocar=xnomarchi.length - xinicio;
                    // console.log('longitud= ' + xnomarchi.length);
                    // console.log('restocar=longi-25 = ' + xrestocar);

                        xfin=xrestocar;  //inicializar
                        for(i=1; i<=xrestocar; i++){
                        //    console.log(xnomarchi.substring(xinicio+i,xinicio+i+1));
                            if(xnomarchi.substring(xinicio+i,xinicio+i+1)=='_'){
                                xfin=i;
                                break;  //salir del ciclo
                            }
                        }
                        xcadena=xnomarchi.substring(xinicio,xinicio + xfin);
                        return xcadena;
                    }
                    if(xparte=='nombre')
                    {
                        //aqui se inicia en el punto del numero de documento y se vá hasta que encuentre el caracter _,
                        //luego se va desde ese punto hasta el final
                        xinicio=25;
                        xrestocar=xnomarchi.length -xinicio;
                        xfin=xrestocar;  //inicializar
                        for(i=1; i<=xrestocar; i++){
                            if(xnomarchi.substring(xinicio+i,xinicio+i+1)=='_'){
                                xfin=i;
                                break;  //salir del ciclo
                            }
                        }
                        xcadena=xnomarchi.substring(xinicio+xfin,xnomarchi.length-4); //el menos 5 recorta la extensión .pdf
                        xcadena=xcadena.replace(/_/g, " ");  //busca el _ y lo reemplza con un espacio
                        return xcadena;
                    }
                }
            },
            descargarArchivo(pnombrearchivo,ptipodescarga,ptipoarchi='',pdocumento='',pfechaini='',pfechafin=''){
               var url='/descargararchivo_ext?nombrearchivo=' + pnombrearchivo +
                       '&tipodescarga=' + ptipodescarga + '&tipoarchi=' + ptipoarchi + '&documento=' +
                        pdocumento + '&fechaini=' + pfechaini + '&fechafin=' + pfechafin;
               axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob', // importante. se debe crear un campo tipo Blob en Javascript
                }).then((response) => {
                    //console.log(response.data);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', pnombrearchivo);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log('Error en la Descarga del archivo');
                });
            },
            descargarArchivoZip(pnombrearchivo,ptipodescarga,ptipoarchi='',pdocumento='',pfechaini='',pfechafin=''){
                let clase=this;
                swal({
                    title:'Estamos Comprimiendo sus archivos',
                    imageUrl: 'https://raultecnologia.files.wordpress.com/2009/02/compresion.gif',
                    imageHeight: 200,
                    imageAlt: 'Cargando'
                })
                axios.get('/descargartodos_ext?nombrearchivo=' + pnombrearchivo +
                       '&tipodescarga=' + ptipodescarga + '&tipoarchi=' + ptipoarchi + '&documento=' +
                        pdocumento + '&fechaini=' + pfechaini + '&fechafin=' + pfechafin).then(function (response) {
                        //console.log(response.data);
                        location.href='/archivos/'+response.data.trim();

                        //clase.eliminar(response.data);
                        })


            },

            verArchivo(parchivo){
               let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.get('/verArchivo_ext?archivo='+parchivo).then(function (response) {  //el verbo get trae los datos
                    clase.archivop=response.data;
                    //console.log(response.data);
                    //var nuevaVen
                    window.open("/archivos/"+response.data.trim(),'Certificado de Aptitud Laboral',true);
                    //setTimeout(clase.eliminar(response.data),3000);

                })
                .catch(function (error) {
                    console.log(error);
                });

            },
            eliminar(parchivo){
            let clase=this; //declaramos una variable para recibir toda la data del objeto response
            this.parchivo=''
                axios.get('/eliminarext?&archivo='+this.parchivo).then(function (response) {  //el verbo get trae los datos

            })
            },
            nombreEmp(){
                let clase=this;
                axios.get('/nombreemp').then(function(response){
                    clase.nombre=response.data;
                })

            }
        },

        mounted() {
            this.eliminar();
            this.nombreEmp();
        }
    }
</script>

<style>

        .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }
</style>
