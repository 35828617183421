<template>

            <div class="modal-dialog modal-dialog-centered modal-primary" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cambiar Contraseña Usuario</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Email</label>
                                <div class="col-md-9">
                                    <input type="text" v-model="email" class="form-control" placeholder="" readonly>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Clave Actual</label>
                                <div class="col-md-9">
                                    <input type="password" v-model="claveactu" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Nueva Clave</label>
                                <div class="col-md-9">
                                    <input type="password" v-model="nuevacla" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Confirmar Nueva Clave</label>
                                <div class="col-md-9">
                                    <input type="password" v-model="confnuevacla" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                    </div>
                                </div>
                            </div>
                            <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in arrayMensajeErrores" :key="error" v-text="error">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-primary" @click="validarpasact()">Actualizar</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        <!--Fin del modal-->
</template>

<script>
    export default{
        props: ["email"],
        data(){
            return{
            claveactu:'',
            nuevacla:'',
            confnuevacla:'',
            errores : 0,    //para saber si hay errores en la validación
            mensajeErrores : [],
            arrayMensajeErrores : []
            }
        },
        methods: {
           validarpasact(email=this.email,claveactu=this.claveactu){
               if(this.validarPassword()){
                    return; //SI HAY ERRORES RETORNA
                }
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/validarpasact?email='+ email + '&claveactu=' + claveactu;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    //console.log(response);
                    clase.arrayMensajeErrores=response.data.mensaje;
                    if(clase.arrayMensajeErrores==0)
                    clase.cambiarpassword();
                    else{
                    swal({
                    title: 'La contraseña ingresada como clave actual no coincide con la almacenada en nuestra base de datos',
                    text: "",
                    type: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar!'
                    })
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            cambiarpassword(){
                axios.post('/cambiarpassword',{
                    'email' : this.email,
                    'nuevacla' : this.nuevacla
                }).then(function (response) {  //el verbo post envía los datos
                    swal({
                    title: 'La Contraseña se ha actualizado exitosamente',
                    text: "",
                    type: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Aceptar!'
                    })
                     axios.post('/logout');
                    window.location.href='/showLogin';

                })
                .catch(function (error) {
                     //alert ('entro aqui al error');
                    console.log(error);
                });
            },
            validarPassword(){
                this.errores=0;
                this.mensajeErrores=[];
                if(!this.email)this.mensajeErrores.push("Debe Ingresar el Email del Usuario");
                if(!this.claveactu)this.mensajeErrores.push("Debe ingresar la clave Actual");
                if(!this.nuevacla)this.mensajeErrores.push("Por favor ingrese la nueva clave");
                if(!this.confnuevacla)this.mensajeErrores.push("Debe Confirmar la nueva contraseña del usuario");
                if(this.nuevacla != this.confnuevacla)this.mensajeErrores.push("La contraseña no se confirmo correctamente");
                if(this.nuevacla == this.claveactu)this.mensajeErrores.push("La nueva contraseña es igual a la clave actual");
                if(this.mensajeErrores.length) this.errores=1;
                return this.errores;

            },
        },
        mounted() {


            }



    }

</script>

<style>
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
    }
    .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }

</style>
