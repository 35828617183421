<template>
 <div class="container-fluid">
        <div class="row mt-5">
            <div class="col-xl-6 mb-5 mb-xl-0">
                <div class="card shadow">
                    <br>
                    <br>
                    <div class="row justify-content-center">
                        <img src="" id="logoclien" class="img-fluid" width="300" height="300"/>
                    </div>
                    <div class="upload-btn-wrapper" style="text-align:center;">
                        <button class="btn btn-primary">Cambiar Logo</button>
                        <input type="file" name="logo" @change="obtenerImagen" accept="image/*"/>
                    </div>
                    <br>
                    <br>
                    <div v-show="errores" class="form-group row div-error">
                        <div class="text-center text-error">
                            <div v-for="error in mensajeErrores" :key="error" v-text="error">
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <div class="col-xl-6 mb-5 mb-xl-0">
                <div class="card shadow">
                    <br>
                   <div class="row">
                        <label class="col-md-3 form-control-label" for="text-input">Nombre</label>
                        <div class="col-md-8">
                            <input type="text" v-model="nombre" class="form-control" placeholder="Nombre Cliente">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <label class="col-md-3 form-control-label" for="text-input">Email</label>
                        <div class="col-md-8">
                            <input type="text" v-model="email" class="form-control" placeholder="Email">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <label class="col-md-3 form-control-label" for="text-input">Dirección</label>
                        <div class="col-md-8">
                            <input type="text" v-model="direccion" class="form-control" placeholder="Dirección">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <label class="col-md-3 form-control-label" for="text-input">Telefono</label>
                        <div class="col-md-8">
                            <input type="text" v-model="telefono" class="form-control" placeholder="Telefono">
                        </div>
                    </div>
                    <br>
                    <div class="row justify-content-center" >
                        <div class="col-md-8" >
                            <div class="progress" style="height: 20px;">
                                <!--<div class="progress-bar" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax={{espacio_total}}></div>-->

                            </div>

                        </div>
                        <br>
                        <p><strong>{{espacio_disponible}} Disponibles de {{espacio_total}}</strong></p>
                    </div>
                    <br>
                </div>



            </div>
        </div>
            <div style="text-align:center;" >
                <button class="btn btn-primary" @click="guardar">Guardar Datos</button>
            </div>
 </div>
</template>
<script>
export default {
        data(){
            return {
                logo:'',
                nombre:'',
                email:'',
                direccion:'',
                telefono:'',
                titulo:'',
                nombrelogo:'',
                espacio_total:0,
                espacio_usado:0,
                espacio_disponible:0,
                datosclien:[],
                errores : 0,    //para saber si hay errores en la validación
                mensajeErrores : []

            }
        },

        methods:{
            convertir(pdato,decimal){
                if(pdato == 0) return '0 Bytes';
                var k = 1024,
                    dm = decimal <= 0 ? 0 : decimal || 2,
                    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(pdato) / Math.log(k));
                return parseFloat((pdato / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },

            dataclien(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/perfil?carpeta='+this.nombre;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.datosclien=respuesta.dataclien;
                    //console.log(clase.datosclien);
                    clase.logo=clase.datosclien[0]['nombrelogo'];
                    clase.nombre=clase.datosclien[0]['nombre'];
                    clase.email=clase.datosclien[0]['email'];
                    clase.direccion=clase.datosclien[0]['direccion'];
                    clase.telefono=clase.datosclien[0]['telefono'];
                    clase.nombrelogo=clase.datosclien[0]['nombrelogo'];
                    clase.espacio_total=clase.datosclien[0]['espacio_disco'];
                    var logoclien = document.getElementById("logoclien");
                    logoclien.setAttribute("src", "argon/img/brand/"+clase.logo);

                    var peso=((clase.espacio_total)-(clase.espacio_usado));
                    clase.espacio_disponible=clase.convertir(peso);
                    clase.espacio_total=clase.convertir(clase.espacio_total);


                })
                .catch(function (error) {
                    console.log('Error en consulta de los datos del cliente');
                });
            },

            validarArchivo(){
                this.errores=0;
                this.mensajeErrores=[];
                if(this.logo.size>2000000)this.mensajeErrores.push("La imagen supera el tamaño maximo permitido");
                if(this.mensajeErrores.length) this.errores=1;
                return this.errores;

            },
            obtenerImagen(event){
               this.logo = event.target.files[0];
               var exten=this.logo.type.substring(6);
               if(this.validarArchivo()){
                   this.logo='';
                    return; //SI HAY ERRORES RETORNA
               }
               var titulo=this.nombrelogo.split(".",1);
               this.titulo=titulo[0]+'.'+exten;
               console.log(this.titulo);
               const objectURL = URL.createObjectURL(this.logo);
               var logoclien = document.getElementById("logoclien");
               logoclien.setAttribute("src", objectURL);
                avatar.setAttribute("src", objectURL);
                avatar1.setAttribute("src", objectURL);
            },
            espacio(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/espacio';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    clase.espacio_usado=response.data['peso'];
                    //clase.espacio_usado=clase.convertir(clase.espacio_usado);

            })
            },

            guardar(){
                var data = new FormData();
                    data.append('logo',this.logo);
                    data.append('nombre',this.nombre);
                    data.append('email',this.email);
                    data.append('direccion',this.direccion);
                    data.append('telefono',this.telefono);
                    data.append('titulo',this.titulo);
                    data.append('logoant',this.nombrelogo);
                    data.append('_method','post');
                    //console.log(data);
                    axios.post('/clientes/guardar',data)
                    .then(response=>{
                        if(response.data==1){
                            swal({
                            title: 'Los datos se han actualizado correctamente',
                            text: "",
                            type: 'success',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Aceptar!'
                            })
                        }else{
                            swal({
                            title: 'no se han podido actualizar los datos',
                            text: "",
                            type: 'warning',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Aceptar!'
                            })

                        }
                        window.location.href='/home';

                    })
            }

        },

            mounted() {
                this.espacio();
                this.dataclien();

        }
}
</script>
