<template>
    <div class="modal-dialog modal-dialog-centered modal-primary" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Reportar Problema o Sugerencia</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form action="" method="put" enctype="multipart/form-data" class="form-horizontal">
                        <div class="form-group row">
                            <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Describa lo que Ocurre</label>
                            <div class="col-md-9">
                                <textarea class="form-control"  v-model="queocurre" aria-label="With textarea"></textarea>
                            </div>
                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" id="correo" @change="activar()" v-model="correo">
                            <label class="form-check-label" for="exampleCheck1">Incluir un dirección de Correo electronico</label>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <input type="text" id='email' v-model="email" class="form-control" placeholder="" readonly>
                            </div>
                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" id="captura2" v-model="captura2" >
                            <label class="form-check-label" for="exampleCheck2">Incluir captura de Pantalla</label>
                        </div>
                            <div class="form-control-sm">

                            </div>

                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-primary" @click="problem()">Enviar Reporte</button>
                    </div>
                </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
        email:'',
        queocurre:'',
        correo:'',
        captura2:'',
        ultimoid:'',
        id:''


        }
    },
    methods:{

        problem(){
            let clase=this;
            clase.ultimo();
            var url='/report/problem1?queocurre='+this.queocurre+'&email='+this.email+'&captura2='+this.captura2;
            axios.put(url).then(function(response){
                if(response.data==1){
                    swal({
                        title: 'Hemos recibido su reporte',
                        text: "Si proporcionó un correo electronico muy pronto lo contactaremos",
                        type: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Aceptar!'
                        })

                        clase.eliminar();
                        clase.notificar();

                }
            })
        },
        activar(){
            if (this.correo=='' || this.correo==false){
            this.email='';
            var email=document.getElementById('email');
            email.setAttribute('readonly', 'readonly');
            }else{
            var email=document.getElementById('email')
            email.removeAttribute('readonly');
            }
        },
        eliminar(){
            let clase=this; //declaramos una variable para recibir toda la data del objeto response
            this.parchivo=''
                axios.get('/eliminar/captura1?&archivo='+'').then(function (response) {  //el verbo get trae los datos
                    console.log(response.data);
                    clase.email='',
                    clase.queocurre='',
                    clase.correo='',
                    clase.captura2='',

                    document.getElementById('email').setAttribute('readonly', 'readonly');
                    location.reload();

            })
            },
            ultimo(){
                let clase=this;
                axios.get('/ultimo1').then(function(response){
                    clase.ultimoid=response.data;
                })
            },
            notificar(){
                let clase=this;
                axios.post('/notificar1?&ultimo='+clase.ultimoid).then(function(response){
                    console.log(response.data);
                })
            }

    },
    mounted() {
            }
}
</script>
