<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Informes</li>
        </ol>
        <div class="container-fluid">
            <div class="card">
                <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                    <p>Empresa Seleccionada Actualmente:<strong>{{nombre}}</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align:center;">
                                <p>Empresa Seleccionada Actualmente: <strong>{{nombre}}</strong></p>
                            <p><strong><h3>Para la empresa seleccionada encontramos {{cantidad}} archivos</h3></strong></p>
                            </div>


                <div class="card-body">
                    <div class="form-group row">
                        <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Tipo de Examen</label>
                            <div class="col-md-2">
                                <select class="form-control" v-on:change="filtrar(tipoexam,fechaini,fechafin)" id="tipoexam" v-model="tipoexam">
                                    <option value="" selected>Elegir Tipo de Examen</option>
                                    <option value="ING">Ingreso</option>
                                    <option value="PER">Periodico</option>
                                    <option value="RET">Retiro</option>
                                </select>
                            </div>
                            <label class="col-md form-control-label" for="text-input" style="color:black;">Fecha Inicial</label>
                            <div class="col-md-3">
                                <input type="date" v-on:change="filtrar(tipoexam,fechaini,fechafin)" class="form-control" v-model="fechaini">
                            </div>
                            <label class="col-md form-control-label" for="text-input" style="color:black;">Fecha Final</label>
                            <div class="col-md-3">
                            <input type="date" v-on:change="filtrar(tipoexam,fechaini,fechafin)" class="form-control" v-model="fechafin">
                            </div>

                    </div>

                <button type="submit" @click.prevent="paraexportar()" class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Descargar listado en Excel"
                ><i class="fa fa-file-excel"></i>Listado en Excel</button>
                <button type="submit" @click.prevent="listadoArchivos('','','','')" class="btn btn-primary float-right" data-toggle="tooltip" data-placement="top" title="Descargar listado en Excel"
                            ><i class="fa fa-filter"></i>Quitar Filtros</button>
                     <div class="table-responsive">
                      <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Identificacion</th>
                                <th>Trabajador</th>
                                <th>Tipo de Examen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="archivo in arrayDatos" :key="archivo.id">
                                <td v-text="separar_Nombre_Archivo('fecha','certificado',archivo)"></td>
                                <td v-text="separar_Nombre_Archivo('documento','certificado',archivo)"></td>
                                <td v-text="separar_Nombre_Archivo('nombre','certificado',archivo)"></td>
                                <td v-text="separar_Nombre_Archivo('tipoexam','certificado',archivo)"></td>
                            </tr>
                        </tbody>
                      </table>


                      <div hidden style="text-align:center; font-weight: bold;" id="aviso">
                            <p><strong><h3>No existen datos para mostrar</h3></strong></p>
                        </div>
                    </div>
                    <nav style="text-align:center;">
                            <ul class="pagination" style="display: inline-flex; margin:0; padding:0;"> <!--centrar botones de navegación -->
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                                </li>

                                <li class="page-item" v-for="page in pagesNumber" :key="page.number" v-bind:class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                                </li>

                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                                </li>
                            </ul>
                        </nav>

                </div>
            </div>

        </div>
    </main>
</template>
<script>
export default {
        data(){
            return {
                codempsifab : 0,
                tipoarchi: '',
                documento: '',
                fechaini: '',
                fechafin: '',
                tipoexam:'',
                cantidad:0,
                errores: 0,
                nombre:'',
                arrayArchivos:[],
                arrayDatos:[],
                filtrodata:[],
                mensajeErrores:[],
                    pagination: {
                    'total':0,
                    'current_page':0,
                    'per_page':0,
                    'last_page':0,
                    'from':0,
                    'to':0
                },
                offset:3,  //para la paginación
                criterio:'empresas.nombre',
                buscar:'',
                numpaginas: '',   //por defecto 6 registros por página


            }
        },
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            pagesNumber: function(){
                if(!this.pagination.to){
                    return [];
                }
                var from = this.pagination.current_page - this.offset;
                if(from < 1){
                    from = 1;
                }
                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to= this.pagination.last_page;
                }
                var pagesArray = [];
                while(from <= to){
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            }
        },
        methods:{
            listadoArchivos(tipoarchi,pdocumento,pfechaini,pfechafin){
                this.tipoexam='',
                this.fechaini='',
                this.fechafin=''
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/useremp/listadoArchivos?tipoarchi=' + this.tipoarchi + '&documento=' +
                        pdocumento + '&fechaini=' + pfechaini + '&fechafin=' + pfechafin;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                   clase.arrayArchivos=response.data;
                   clase.arrayDatos=clase.arrayArchivos;
                   clase.cantidad=clase.arrayDatos.length;
                   //if(clase.arrayArchivos.length==0){
                   //     document.getElementById('aviso').removeAttribute('hidden');
                   //}else{
                    //   document.getElementById('aviso').setAttribute('hidden', 'hidden');
                   //}
                })
            },
            validarDatos(){
                this.errores=0;
                this.mensajeErrores=[];
                if(this.fechaini>this.fechafin)this.mensajeErrores.push("la fecha inicial no puede ser mayor a la fecha fina");
                //si existen errores hasta aquí, retorne
                if(this.mensajeErrores.length) this.errores=1;

                return this.errores;
            },
            separar_Nombre_Archivo(xparte,xtipoarchi,xnomarchi)
            {
                //el formato del nombre es:
                //tipoarchivo_tipoexamen(3)_fecha(8)_documento_nombres.pdf
                //substring de javascript ta encuenta el inicio y no los caracteres a extraer sino la posicion final.
                //asi un substring(10,15) extrae 5 caracteres desde la posición 10 hasta la 15.
                var xcadena,xdia,xmes,xano,xinicio,xfin,xrestocar,i;
                xcadena="";
                if(xtipoarchi.toUpperCase()=='CERTIFICADO'){
                    if(xparte=='tipoexam'){
                        xcadena=xnomarchi.substring(12,15);
                        return xcadena;
                    }
                    if(xparte=='fecha'){
                                                                    //16 EL GUION
                        xdia=xnomarchi.substring(16,18);//CERTIFICADO_ING_02012018_1010109110_LUISA_MARIA_MARTINEZ_HOLGUIN
                        xmes=xnomarchi.substring(18,20);
                        xano=xnomarchi.substring(20,24);
                        xcadena=xdia + '/' + xmes + '/' + xano;
                        return xcadena;
                    }
                    if(xparte=='documento')
                    {
                        //aqui se inicia en el punto del numero de documento y se vá hasta que encuentre el caracter _
                        xinicio=25;

                        xrestocar=xnomarchi.length - xinicio;
                    // console.log('longitud= ' + xnomarchi.length);
                    // console.log('restocar=longi-25 = ' + xrestocar);

                        xfin=xrestocar;  //inicializar
                        for(i=1; i<=xrestocar; i++){
                        //    console.log(xnomarchi.substring(xinicio+i,xinicio+i+1));
                            if(xnomarchi.substring(xinicio+i,xinicio+i+1)=='_'){
                                xfin=i;
                                break;  //salir del ciclo
                            }
                        }
                        xcadena=xnomarchi.substring(xinicio,xinicio + xfin);
                        return xcadena;
                    }
                    if(xparte=='nombre')
                    {
                        //aqui se inicia en el punto del numero de documento y se vá hasta que encuentre el caracter _,
                        //luego se va desde ese punto hasta el final
                        xinicio=25;
                        xrestocar=xnomarchi.length -xinicio;
                        xfin=xrestocar;  //inicializar
                        for(i=1; i<=xrestocar; i++){
                            if(xnomarchi.substring(xinicio+i,xinicio+i+1)=='_'){
                                xfin=i;
                                break;  //salir del ciclo
                            }
                        }
                        xcadena=xnomarchi.substring(xinicio+xfin,xnomarchi.length-4); //el menos 5 recorta la extensión .pdf
                        xcadena=xcadena.replace(/_/g, " ");  //busca el _ y lo reemplza con un espacio
                        return xcadena;
                    }
                }
            },
            filtrar(ptipo,pfechaini,pfechafin){
                if(ptipo!=''&& pfechaini=='' &&pfechafin==''){
                    for(var i=0;i < this.arrayArchivos.length; i+=1){
                    var datosfil=this.arrayArchivos[i].substring(12,15);

                    if (datosfil==ptipo){
                        this.filtrodata.push(this.arrayArchivos[i])

                    }

                    }
                    this.arrayDatos=[];
                    this.arrayDatos=this.filtrodata;
                    this.cantidad=this.arrayDatos.length;

                    this.filtrodata=[];
                }
                else if(ptipo!=''&& pfechaini!='' &&pfechafin==''){
                for(var i=0;i < this.arrayArchivos.length; i+=1){
                    var datosfil=this.arrayArchivos[i].substring(12,15);
                    var xdia=this.arrayArchivos[i].substring(16,18);
                    var xmes=this.arrayArchivos[i].substring(18,20);
                    var xano=this.arrayArchivos[i].substring(20,24);
                    var xcadena=xano + '-' + xmes + '-' + xdia;


                    if (xcadena>=pfechaini && datosfil==ptipo){
                        this.filtrodata.push(this.arrayArchivos[i])

                    }

                    }
                    this.arrayDatos=[];
                    this.arrayDatos=this.filtrodata;
                    this.cantidad=this.arrayDatos.length;

                    this.filtrodata=[];
                }
                else if(ptipo!=''&& pfechaini!='' &&pfechafin!=''){
                    for(var i=0;i < this.arrayArchivos.length; i+=1){
                    var datosfil=this.arrayArchivos[i].substring(12,15);
                    var xdia=this.arrayArchivos[i].substring(16,18);
                    var xmes=this.arrayArchivos[i].substring(18,20);
                    var xano=this.arrayArchivos[i].substring(20,24);
                    var xcadena=xano + '-' + xmes + '-' + xdia;


                    if (xcadena>=pfechaini && xcadena<=pfechafin && datosfil==ptipo){
                        this.filtrodata.push(this.arrayArchivos[i])

                    }

                    }
                    this.arrayDatos=[];
                    this.arrayDatos=this.filtrodata;
                    this.cantidad=this.arrayDatos.length;

                    this.filtrodata=[];

                }
                else if(ptipo==''&& pfechaini!='' &&pfechafin==''){
                    for(var i=0;i < this.arrayArchivos.length; i+=1){
                        var xdia=this.arrayArchivos[i].substring(16,18);
                        var xmes=this.arrayArchivos[i].substring(18,20);
                        var xano=this.arrayArchivos[i].substring(20,24);
                        var xcadena=xano + '-' + xmes + '-' + xdia;
                        var datosfil=xcadena

                if (datosfil>=this.fechaini){
                     this.filtrodata.push(this.arrayArchivos[i])

                }

                }
                this.arrayDatos=[];
                this.arrayDatos=this.filtrodata;
                this.cantidad=this.arrayDatos.length;

                this.filtrodata=[];
                }
                else if(ptipo==''&& pfechaini!='' &&pfechafin!=''){
                    if(this.validarDatos()==1)return;
                    for(var i=0;i < this.arrayArchivos.length; i+=1){
                        var xdia=this.arrayArchivos[i].substring(16,18);//CERTIFICADO_ING_02012018_1010109110_LUISA_MARIA_MARTINEZ_HOLGUIN
                        var xmes=this.arrayArchivos[i].substring(18,20);
                        var xano=this.arrayArchivos[i].substring(20,24);
                        var xcadena=xano + '-' + xmes + '-' + xdia;
                        var datosfil=xcadena

                if (datosfil>=this.fechaini && datosfil<=this.fechafin){
                     this.filtrodata.push(this.arrayArchivos[i])

                }

                }
                this.arrayDatos=[];
                this.arrayDatos=this.filtrodata;
                this.cantidad=this.arrayDatos.length;

                this.filtrodata=[];
                }

            },

            cambiarPagina(page,textobuscar,condicion){
                this.pagination.current_page = page;
                //envia la petición para visualizar la data de esa página
                this.listadoEmpresas(page,textobuscar,condicion);
            },
            paraexportar(){
                for(var i=0;i < this.arrayArchivos.length; i+=1){
                var fecha=this.separar_Nombre_Archivo('fecha','certificado',this.arrayArchivos[i]);
                var documento=this.separar_Nombre_Archivo('documento','certificado',this.arrayArchivos[i]);
                var nombre=this.separar_Nombre_Archivo('nombre','certificado',this.arrayArchivos[i]);
                var tipoexam=this.separar_Nombre_Archivo('tipoexam','certificado',this.arrayArchivos[i]);

                this.filtrodata.push({'fecha':fecha,'identificacion':documento,'nombre':nombre,'tipo de examen':tipoexam});

                }
                this.exportExcel()

            },
            exportExcel: function () {
                let data = XLSX.utils.json_to_sheet(this.filtrodata)
                const workbook = XLSX.utils.book_new()
                const filename = 'Informe de Certificados'
                XLSX.utils.book_append_sheet(workbook, data, filename)
                XLSX.writeFile(workbook, `${filename}.xlsx`)

                this.filtrodata=[];
            },
            nombreEmp(){
                let clase=this;
                axios.get('/nombreemp').then(function(response){
                    clase.nombre=response.data;
                })

            }
        },

            mounted() {
            this.listadoArchivos(this.tipoarchi,this.documento,this.fechaini,this.fechafin);
            this.nombreEmp();

        }
}
</script>
