<template>

                            <form role="form" method="POST" action="">
                            <div class="form-group mb-3">
                                <div v-show="errores" class="form-group row div-error">
                                    <div class="text-center text-error">
                                        <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                        </div>
                                    </div>
                                </div>

                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                                    </div>
                                    <input class="form-control" v-model="email" placeholder="Email" type="email" name="email" value="" required autofocus>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                                    </div>
                                    <input class="form-control" @keyup.enter="validar()" v-model="password" name="password" placeholder="Password" type="password" required>
                                </div>

                            </div>
                            <div class="custom-control custom-control-alternative custom-checkbox">
                                <input class="custom-control-input" name="remember" id="customCheckLogin" type="checkbox">
                                <label class="custom-control-label" for="customCheckLogin">
                                    <span class="text-muted">Recordarme</span>
                                </label>
                                <div class="row mt-3">
                                        <div class="col-8">
                                            <a href="password/reset">
                                                    <small>Olvidaste tu Contraseña?</small>
                                            </a>
                                        </div>
                                    </div>
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn btn-primary my-4" @click.prevent="validar()">Iniciar Sesión</button>
                            </div>
                            <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in mensajeErrores1" :key="error" v-text="error">
                                    </div>
                                </div>
                            </div>
                        </form>

</template>

<script>
export default {
    data(){
        return{
        email:'',
        password:'',
        mensaje:'',
        errores : 0,
        mensajeErrores : [],
        mensajeErrores1 : [],
        arrayMensajeErrores : []
        }
    },

    methods: {
        validar(email=this.email,password=this.password){
            if(this.validarCampos()){
                    return; //SI HAY ERRORES RETORNA
                }
                swal({
                    title:'Iniciando Sesión',
                    imageUrl: ('./argon/img/icons/carga.gif'),
                    imageHeight: 200,
                    imageAlt: 'Ingresando...'
                })
                var data= new FormData();
                data.append('email',this.email);
                data.append('password',this.password);
                data.append('_method','post');
                let clase=this; //declaramos una variable para recibir toda la data del objeto response

                axios.post('/validar',data).then(function (response) {  //el verbo get trae los datos
                    //console.log(response);
                    clase.mensaje=response.data.mensaje;
                    if(clase.mensaje==0){
                        clase.login();
                    }else{
                        if(clase.mensaje==1){
                            swal({
                            title: 'Por su seguridad es necesario que cambie su contraseña antes de ingresar al sitio por favor presione la opción olvide mi contraseña y siga los pasos.',
                            text: "",
                            type: 'warning',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Aceptar!'
                        })}else{
                            swal({
                                title: 'El usuario se encuentra inactivo.Comuniquese con el administrador del sistema para activar nuevamente el usuario',
                                text: "",
                                type: 'warning',
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Aceptar!'
                            })

                        }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            login(){
                var data= new FormData();
                data.append('email',this.email);
                data.append('password',this.password);
                data.append('_method','post');
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.post('/login',data).then(function (response) {

                    location.href='/home';
                }).catch(function (error) {
                    swal({
                        title:'Los datos ingresados no Son validos',
                        imageUrl: 'https://gifimage.net/wp-content/uploads/2017/11/error-gif-animado-10.gif',
                        imageHeight: 200,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Aceptar!'
                    })
                    });
            },
            validarCampos(){
                this.errores=0;
                this.mensajeErrores=[];
                if(!this.email)this.mensajeErrores1.push("Debe digitar el email");
                if(!this.password)this.mensajeErrores1.push("Debe digitar la contraseña");
                //si existen errores hasta aquí, retorne
                if(this.mensajeErrores1.length) this.errores=1;

                return this.errores;
            }

}
}
</script>
