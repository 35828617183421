<template>
    <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm">
            <thead>
            <tr>
                <th>Empresa</th>
                <th>Email</th>
                <th>Nombre</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="usuario in arrayUltUsuarios  " :key="usuario.id">
                <td v-text="usuario.nombreemp"></td>
                <td v-text="usuario.email"></td>
                <td v-text="usuario.nombre"></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "ultimos_usuario.vue",
        data(){
            return {
                arrayUltUsuarios:[],
            }
        },
        methods:{
            ultusuarios(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/usuarios/ultusuarios';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.arrayUltUsuarios=respuesta.ultusuarios;
                })
                    .catch(function (error) {
                        console.log('Error en consulta de selección de los ultimos usuarios');
                    });
            },

        },
        mounted() {
            this.ultusuarios();
        }
    }
</script>

<style scoped>

</style>
