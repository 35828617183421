<template>
    <div class="table-responsive">
        <table class="table table-bordered table-striped table-sm">
            <thead>
            <tr>
                <th>Código SIFAB</th>
                <th>Nombre</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="empresa in arrayUltEmpresas" :key="empresa.id">
                <td v-text="empresa.codempsifab"></td>
                <td v-text="empresa.nombre"></td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
    export default {
        name: "ultimas_empresas.vue",
        data(){
            return {
                arrayUltEmpresas:[],

            }
        },
        methods: {
            ultempresas() {
                let clase = this; //declaramos una variable para recibir toda la data del objeto response
                var url = '/empresas/ultimasemp';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta = response.data;
                    clase.arrayUltEmpresas = respuesta.empresas;
                })
                    .catch(function (error) {
                        console.log('Error en consulta de selección de las ultimas empresas');
                    });
            }
        },
        mounted() {
            this.ultempresas();
        }
    }
</script>

<style scoped>

</style>
