<template>
 <form role="form" method="POST" action="">

                            <div class="form-group mb-3">
                                <div v-show="errores" class="form-group row div-error">
                                    <div class="text-center text-error">
                                        <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ni ni-single-02"></i></span>
                                    </div>
                                    <input class="form-control" v-model="usuario" placeholder="Usuario Sai en linea" type="email" name="email" value="" required autofocus>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                                    </div>
                                    <input class="form-control" v-model="email" placeholder="Email al que desea recibir la notificación" type="email" name="usuario" value="" required autofocus>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn btn-primary my-4" @click="eliminar_tokens()">Enviar Vinculo para Restablecer contraseña</button>
                            </div>
                            <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in mensajeErrores1" :key="error" v-text="error">
                                    </div>
                                </div>
                            </div>
                        </form>
</template>

<script>
export default {
    data(){
        return{
        email:'',
        usuario:'',
        token:'',
        mensaje:'',
        errores : 0,
        mensajeErrores : [],
        mensajeErrores1 : [],
        arrayMensajeErrores : []
        }
    },

    methods: {
        validar(email=this.email,usuario=this.usuario){
            if(this.validarCampos()){
                    return; //SI HAY ERRORES RETORNA
                }
                let clase=this;
                axios.post('/password/validarext?usuario='+usuario).then(function(response){
                    if(response.data==1){
                        clase.mensajeErrores.push("El usuario no se encuentra en nuestra base de datos");
                        if(clase.mensajeErrores.length) clase.errores=1;
                        return clase.errores;
                    }else{
                        clase.usuario=response.data.email;
                        clase.token=response.data.token;
                        clase.enviar_mail();
                    }
                })
            },
            enviar_mail(email=this.email,usuario=this.usuario,token=this.token){
                let clase=this;
                axios.post('/password/emailext?usuario='+usuario +'&email='+email+'&token='+token)
                .then(function(reponse){
                    window.alert('le hemos enviado un correo con los pasos para restablecer su contraseña,si no el mensaje en su bandeja de entrada por favor revise su bandeja de spam o correo no deseado');
                    window.location.href='/empresaext';
                })

            },
            validarCampos(){
                this.errores=0;
                this.mensajeErrores=[];
                if(!this.email)this.mensajeErrores1.push("debe digitar el email al que desea que le enviemos los pasos para restablecer su contraseña");
                if(!this.usuario)this.mensajeErrores1.push("debe digitar el usuario que usa para ingresar a sai en linea");
                //si existen errores hasta aquí, retorne
                if(this.mensajeErrores1.length) this.errores=1;

                return this.errores;
            },
            eliminar_tokens(){
                let clase=this;
                axios.post('/password/eliminar_ext?usuario='+this.usuario).then(function(response) {
                    clase.validar();
                })
                }

        }
}
</script>
