<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Relación de Usuarios con Empresas</li>
        </ol>
        <div class="container-fluid">
            <!-- Ejemplo de tabla Listado -->
            <div class="card">
                <div class="card-header">
                  <i class="fa fa-align-justify"></i> Relación de Usuarios con Empresas
                </div>
                <div class="card-body">
                    <div class=" form-group row">
                        <div class="col-md-5">
                            <div class="input-group">
                                <select class="form-control col-md-4" v-model="criterio">
                                    <option value="empresas.nombre">Empresa</option>
                                    <option value="useremp.email">Email</option>
                                    <option value="useremp.nombre">Nombre Usuario</option>
                                     <option value="useremp.cargo">Cargo</option>
                                </select>
                                <input type="text" v-model="buscar" @keyup.enter="listadoUsuariosemp(1,buscar,criterio)" class="form-control" placeholder="Texto a buscar">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button type="submit" @click="listadoUsuariosemp(1,buscar,criterio)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                        </div>
                        <div class="col-md-5">
                            <select class="form-control col-md-6" v-model="numpaginas" @change="listadoUsuariosemp(1,buscar,criterio,numpaginas)">  <!--  -->
                                            <option value="" selected>Registros por Página</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="30">30</option>
                                            <option value="35">35</option>
                                            <option value="40">40</option>
                                            <option value="45">45</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                        </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-bordered table-striped table-sm">
                        <caption>Lista de Usuarios y Empresa(s) Relacionadas</caption>
                        <thead>
                            <tr>
                                <th>Empresa</th>
                                <th>Email</th>
                                <th>Nombre</th>
                                <th>Cargo</th>
                                <th>Estado</th>
                                 <th>Asociar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="usuarioemp in arrayUsuarios" :key="usuarioemp.llave">  <!--llave no existe, no se utiliza, es para que no marque error si se quita el key -->
                                <td v-text="usuarioemp.nombreemp"></td>
                                <td v-text="usuarioemp.email"></td>
                                <td v-text="usuarioemp.nombre"></td>
                                <td v-text="usuarioemp.cargo"></td>
                                <td>
                                    <div v-if="usuarioemp.estado=='A'">
                                        <span class="badge badge-success">Activo</span>
                                    </div>
                                        <div v-else>
                                        <span class="badge badge-danger">Inactivo</span>
                                    </div>
                                </td>
                                <td>
                                    <button type="button" @click="abrirVentanaModal(usuarioemp)" data-toggle="tooltip" data-placement="top" title="Ver Empresas Relacionadas"
                                    class="btn btn-info btn-sm">
                                       <i class="fas fa-edit"></i>
                                    </button> &nbsp;
                                </td>
                            </tr>
                        </tbody>
                      </table>
                      <div hidden style="text-align:center; font-weight: bold;" id="aviso">
                            <p><strong><h3>No existen datos para mostrar</h3></strong></p>
                        </div>
                    </div>
                        <nav style="text-align:center;">
                            <ul class="pagination" style="display: inline-flex; margin:0; padding:0;"> <!--centrar botones de navegación -->
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                                </li>

                                <li class="page-item" v-for="page in pagesNumber" :key="page.number" v-bind:class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                                </li>

                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                                </li>
                            </ul>
                        </nav>
                </div>
            </div>
            <!-- Fin ejemplo de tabla Listado -->
        </div>
         <!--Inicio del modal agregar/actualizar-->
        <div class="modal fade" tabindex="-1" :class="{'mostrar' : vermodal}" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog-scrollable.modal-dialog modal-primary modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" v-text="tituloVentana"></h4>
                        <button type="button" class="close" @click="cerrarVentanaModal()" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Email</label>
                                <div class="col-md-9">
                                    <input type="text" v-model="email" class="form-control" readonly>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 form-control-label" for="text-input" style="color:white;">Nombre del Usuario</label>
                                <div class="col-md-9">
                                    <input type="text" v-model="nombre" class="form-control" readonly>
                                </div>
                            </div>
                            <div class="form-group row">

                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <caption>Lista de Empresas Relacionadas con el Usuario</caption>
                                            <thead>
                                                <tr>
                                                    <th>Código de la Empresa SIFAB</th>
                                                    <th>Nombre de la Empresa</th>
                                                    <th>Inactivar/Activar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="usuarioemp in arrayUsuariosnoPaginado" :key="usuarioemp.llave" v-if="usuarioemp.email==email">  <!--llave no existe, no se utiliza, es para que no marque error si se quita el key -->

                                                    <td v-text="usuarioemp.codempsifab"></td>
                                                    <td v-text="usuarioemp.nombreemp"></td>
                                                    <td>
                                                        <div>
                                                            <template v-if="usuarioemp.estadoreg=='A'">
                                                               <button type="button" class="btn btn-danger btn-sm"  @click="inactivarEmpresaUsuario(usuarioemp.idemprel,usuarioemp.email)" data-toggle="tooltip" data-placement="top" title="Inactivar Empresa del Usuario">
                                                                    <i class="fas fa-trash-alt"></i>
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                <button type="button" class="btn btn-success btn-sm"  @click="activarEmpresaUsuario(usuarioemp.idemprel,usuarioemp.email)" data-toggle="tooltip" data-placement="top" title="Activar Empresa al Usuario">
                                                                    <i class="fas fa-check"></i>
                                                                </button>
                                                            </template>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>  <!-- table responsive -->
                                <!-- fin div col-md-12 -->
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 form-control-label" for="text-input" style="color:white;">Empresas Activas</label>
                                <div class="col-md-9">
                                    <div class="input-group" >
                                        <select class="form-control" v-model="idempresa">
                                            <option value="0" disabled>Seleccione</option>
                                            <option v-for="empresa in arrayEmpresas" :key="empresa.id" :value="empresa.id" v-text="empresa.nombre"></option>

                                        </select>
                                        <div class="col-md-3">
                                            <button type="button" class="btn btn-secondary" @click="agregarEmpresaUsuario()">Agregar Empresa</button>
                                        </div>
                                    </div>
                               </div>
                            </div>
                            <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-text="mensajeError">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="cerrarVentanaModal()">Cerrar Ventana</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!--Fin del modal-->
</main>
</template>

<script>
    export default {
         data(){
            return {
                email: '',
                nombre :'',
                cargo :'',
                arrayUsuarios: [],
                arrayUsuariosnoPaginado: [], //este array posee toda la data de la consulta. el arrayusuarios solo tiene la data
                                            //de cada página. esto origina que cuando se vayan a mostrar las empresas asociadas
                                            //a un usuario, si no están en la misma página no se muestran las empresas
                                            //que estén por fuera de la pagina actual.

                arrayEmpresas: [],  //para cargar las empresas a la vista modal
                vermodal : 0,   //para controlar si se ve la ventana modal
                tituloVentana: '',
                pagination: {
                    'total':0,
                    'current_page':0,
                    'per_page':0,
                    'last_page':0,
                    'from':0,
                    'to':0
                },
                offset:3,  //para la paginación
                criterio: 'useremp.nombre',
                buscar: '',
                numpaginas: '',   //por defecto 5 registros por página
                idempresa : 0,  //para agregar empresas al usuario
                idusuario : 0,   //para agregar empresas al usuario
                errores: 0,
                mensajeError: ''
            }
         },
         //para la paginación
         computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            pagesNumber: function(){
                if(!this.pagination.to){
                    return [];
                }
                var from = this.pagination.current_page - this.offset;
                if(from < 1){
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to= this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to){
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            }
        },
        methods: {
            mostraralerta(){
                alert('evento');  //para observar la ejecucuín de eventos
            },
            listadoUsuariosemp(page,textobuscar,condicion,pnumpaginas=5){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresasrelusu?page='+ page + '&buscar=' + textobuscar + '&criterio=' + condicion + '&numpaginas=' + pnumpaginas;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.arrayUsuarios=respuesta.usuariosemp.data;
                    clase.pagination=response.data.pagination;
                    if(clase.arrayUsuarios.length==0){
                        document.getElementById('aviso').removeAttribute('hidden');
                   }else{
                       //console.log('entro al else');
                       document.getElementById('aviso').setAttribute('hidden', 'hidden');
                   }
                    //console.log(response);
                })
                .catch(function (error) {
                    console.log('Error en consulta');
                });
            },
            listadoTodosUsuariosemp(){
                let clase=this; //esta instruccion es clave ya que si se declara directamente con this, no tomoa el data para
                                //vaciarlo en el arreglo
                var url='/empresasrelusu/listado';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    //console.log(response);
                    var respuesta=response.data;
                    clase.arrayUsuariosnoPaginado=respuesta;
                })
                .catch(function (error) {
                    console.log('Error en consulta de todos los Usuarios o en las instrucciones el método');
                });
            },

            seleccionarEmpresas(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/empresas/listadoempresas';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var respuesta=response.data;
                    clase.arrayEmpresas=respuesta.empresas;
                })
                .catch(function (error) {
                    console.log('Error en consulta de selección de Empresas');
                });
            },
            cambiarPagina(page,textobuscar,condicion){
                this.pagination.current_page = page;
                //envia la petición para visualizar la data de esa página
                this.listadoUsuariosemp(page,textobuscar,condicion);
            },
            cerrarVentanaModal(){
                this.vermodal=0;
                this.email= '';
                this.nombre='';
                this.cargo ='';
                this.tituloVentana='';
                this.idempresa=0;   //borra el valor del combo de empresas
            },

            abrirVentanaModal(data=[]){
                this.vermodal=1;
                this.tituloVentana='Empresas Relacionadas al Usuario : ' + data.email ;
                this.email= data.email;
                this.nombre=data.nombre;
                this.idusuario=data.id; //id del usuario tabla useremp. lo carga en arrayusuario desde el controller
                this.seleccionarEmpresas();
            },
            inactivarEmpresaUsuario(pidempresarel,pemail){
                swal({
                title: 'Esta seguro de Inactivar esta Empresa al usuario: ' + pemail + '?',
                text: "",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar!',
                cancelButtonText: 'Cancelar',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
                }).then((result) => {
                if (result.value) {
                    let clase=this; //declaramos una variable para recibir toda la data del objeto response
                    axios.put('/empresasrelusu/inactivar',{
                        'id' : pidempresarel  //id de la empresa a actualizar. ingresa como parámetro
                    }).then(function (response) {  //el verbo post envía los datos
                        //se ejecuta de forma satisfactoria
                       //clase.listadoEmpresas(1,'','nombre');
                       clase.actualizarvalorArray(pidempresarel,'I');
                        swal(
                        'Inactivada!',
                        'La Empresa Relacionada se ha inactivado correctamente',
                        'success'
                        )
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                } else if (
                    // Read more about handling dismissals
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    //no mostrar mensaje si el usuario cancela
                    /* swal(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                    ) */
                }
                })
            },
            activarEmpresaUsuario(pidempresarel,pemail){
                swal({
                title: 'Esta seguro de Activar esta Empresa al usuario: ' + pemail + '?',
                text: "",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar!',
                cancelButtonText: 'Cancelar',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
                }).then((result) => {
                if (result.value) {
                    let clase=this; //declaramos una variable para recibir toda la data del objeto response
                    axios.put('/empresasrelusu/activar',{
                        'id' : pidempresarel  //id de la empresa a actualizar. ingresa como parámetro
                    }).then(function (response) {  //el verbo post envía los datos
                        //se ejecuta de forma satisfactoria
                       clase.actualizarvalorArray(pidempresarel,'A');
                        swal(
                        'Activada!',
                        'La Empresa se ha activado correctamente',
                        'success'
                        )
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                } else if (
                    // Read more about handling dismissals
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    //no mostrar mensaje si el usuario cancela
                    /* swal(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                    ) */
                }
                })
            },
            actualizarvalorArray(pidempresarel,pvalor){
                //este metodo es para recorrer el array y actualizar un valor en la llave de a empresa relacionada
                //pvalor es A=Activo  I=Inactivo
                //no se carga toda la consulta. se recorre el arreglo, se encuentra el dato y se actualiza para cambiar
                //dinamicamente la vista. se hace sobre el arreglo de todos los usuarios

                this.arrayUsuariosnoPaginado.forEach(function(element) {
                    if (element['idemprel']==pidempresarel){
                        element['estadoreg']=pvalor;
                        return;
                    }
                });
            },
            agregarEmpresaUsuario(){
                if(this.validarEmpresa()){
                    return; //SI HAY ERRORES muestra mensaje y retorna
                }

                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.post('/empresasrelusu/agregar',{
                    'idempresa' : this.idempresa,
                    'idusuario' : this.idusuario
                }).then(function (response) {  //el verbo post envía los datos
                    //se ejecuta de forma satisfactoria
                    alert("la Empresa se ha agregado correctamente");
                    //this.cerrarVentanaModal();
                    clase.listadoTodosUsuariosemp();
                    this.idempresa=0;   //limpiar combo de empresa en ventana modal
                })
                .catch(function (error) {
                        //alert ('entro aqui al error');
                    console.log("Error al agregar empresa");
                });
        },
        validarEmpresa(pidempresa,pidusuario){
                let clase=this;
                this.errores=0;
                if(this.idempresa==0){
                    this.mensajeError="Debe Seleccionar el Nombre de la Empresa";
                    this.errores=1;
                    return 1;
                }
                //validar que la empresa no esté relacionada ya con el usuario
                this.arrayUsuariosnoPaginado.forEach(function(element) {
                    if (element['idcodemp']==clase.idempresa && element['id']==clase.idusuario){
                        clase.mensajeError="La Empresa Seleccionada ya está relacionada al Usuario";
                        clase.errores=1;
                        return 1;
                    }
                });
                return clase.errores;
            }
        },//finalizan los métodos

        mounted() {
           // console.log('Component mounted.')
           this.listadoUsuariosemp(1,this.buscar,this.criterio);
           this.listadoTodosUsuariosemp();  //todos los usuarios para cargarlos en otro arreglo
        }
    }
</script>

<style>
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
     }
     .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;

    }
</style>
