<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Subir Archivos</li>
        </ol>
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-align-justify"></i> Subir Archivos
                </div>
                <div v-show="errores" class="form-group row div-error">
                        <div class="text-center text-error">
                            <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                </div>
                        </div>
                    </div>
                <div class="card-body">
                     <div class="form-group row">
                        <label class="col-md-2 form-control-label" for="text-input" style="color:Black;">Empresa</label>
                        <div class="col-md-5">
                            <div class="input-group" >
                                <select class="form-control" v-model="codempsifab" id="codempsifab">
                                    <option value="0" disabled>Seleccione la Empresa</option>
                                    <option v-for="empresa in arrayEmpresas" :key="empresa.id" :value="empresa.codempsifab" v-text="empresa.nombre"></option>
                                </select>
                            </div>
                        </div>
                        <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Tipo de Archivo</label>
                        <div class="col-md-3">
                            <select class="form-control" v-model="tipoarchi">
                                <option value="" selected>Elegir Tipo de Archivo</option>
                                <option value="CT">Certificados</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">

                        <label class="col-sm-2 form-control-label" for="text-input" style="color:black;">Fecha</label>
                        <div class="col-md-3">
                            <input type="date"  class="form-control" v-model="fecha">
                        </div>
                        <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Tipo de Examen</label>
                        <div class="col-md-5">
                            <select class="form-control" v-model="tipoexam">
                                <option value="" selected>Elegir Tipo de Examen</option>
                                <option value="ING">Ingreso</option>
                                <option value="PER">Periodico</option>
                                <option value="RET">Retiro</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                         <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Documento</label>
                        <div class="col-md-2">
                                <input type="text"  class="form-control" placeholder="Documento" v-model="documento">
                        </div>
                        <label class="col-md-2 form-control-label" for="text-input" style="color:black;">Nombre Y apellidos</label>
                        <div class="col-md-6">
                                <input type="text"  class="form-control" placeholder="Nombres y Apellidos" v-model="nombres">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-10">
                            <input type="file" name="archivo[]" class="form-control" @change="obtenerArchivo" accept=".pdf">
                        </div>

                        <div class="col-md-2">
                            <button type="submit" @click="subirArchivo" class="btn btn-primary"><i class="ni ni-cloud-upload-96"></i> Subir Archivo</button>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-10">
                            <div v-for="archivo in arrayArchivos" :key="archivo.titulo" v-text="archivo.titulo"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </main>
</template>
<script>
import { format } from 'path';

export default {
        data(){
            return {
                codempsifab:'',
                tipoarchi: '',
                fecha: '',
                documento: '',
                nombres: '',
                tipoexam:'',
                espacio_usado:0,
                espacio_total:0,
                espacio_disponible:0,
                arrayEmpresas:[],
                arrayArchivos:[],
                archivo:null,
                errores : 0,    //para saber si hay errores en la validación
                mensajeErrores : []
            }
        },
        methods:{
            listadoEmpresas(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.get('/usuarios_empresa').then(function (response) {  //el verbo get trae los datos
                    clase.arrayEmpresas=response.data;
                   // clase.arrayArchivos=response.data;

                    //console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            obtenerArchivo(event){
               this.archivo = event.target.files[0];
               if(this.validarArchivo()){
                   this.archivo='';
                    return; //SI HAY ERRORES RETORNA
                }
               var nombrear='CERTIFICADO_'+this.tipoexam+'_'+this.fecha.split('-').reverse().join('')+'_'+this.documento+'_'+this.nombres+'.pdf'
               this.arrayArchivos.push({"archivo":this.archivo,"titulo":nombrear,"empresa":this.codempsifab});
               this.limpiardatos();
               //console.log(this.arrayArchivos);
            },
            subirArchivo(){
                for ( var i = 0; i < this.arrayArchivos.length; i+=1) {
                    swal({
                    title:'Estamos Cargando sus archivos',
                    imageUrl: 'https://media0.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=790b7611bb23f83a91ba25903f3763452980bdfdeb9daaaa&rid=giphy.gif',
                    imageHeight: 200,
                    imageAlt: 'Cargando'
                    })
                    var data = new FormData();
                    data.append('archivo',this.arrayArchivos[i]['archivo']);
                    data.append('titulo',this.arrayArchivos[i]['titulo']);
                    data.append('codempsifab',this.arrayArchivos[i]['empresa']);
                    data.append('_method','post');
                    //console.log(data);
                    axios.post('/empresas/subir_archivo',data)
                    .then(response=>{
                        this.limpiardatos();
                    if (i=this.arrayArchivos.length){
                        this.arrayArchivos=[];
                        if(response.data==1){
                            swal({
                            title: 'Los Archivos se han almacenado correctamente',
                            text: "",
                            type: 'success',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Aceptar!'
                            })
                        }else{
                            swal({
                            title: 'no se han podido almacenar los archivos porfavor revise los datos',
                            text: "",
                            type: 'warning',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Aceptar!'
                            })
                        }
                    }
                    })
                }


            },
            espacio(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/espacio';
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    var espacio=response.data;
                    clase.espacio_total=espacio['espacio'];
                    clase.espacio_usado=espacio['peso'];
                    clase.espacio_disponible=((clase.espacio_total)-(clase.espacio_usado));
                    //console.log(clase.espacio_disponible);

            })
            },
            validarArchivo(){
                this.errores=0;
                this.mensajeErrores=[];
                if(this.archivo.size>10000000)this.mensajeErrores.push("El archivo supera el tamaño maximo permitido");
                if(this.archivo.size>this.espacio_disponible)this.mensajeErrores.push("No dispone de suficiente espacio de almacenamiento para cargar el archivo, por favor visite su perfil para comprobarlo");
                if(!this.codempsifab)this.mensajeErrores.push("Debes seleccionar una empresa");
                if(!this.tipoarchi)this.mensajeErrores.push("Debes Seleccionar el tipo de archivo que va a subir");
                if(!this.fecha)this.mensajeErrores.push("Debe elegir la fecha del archivo");
                if(!this.tipoexam)this.mensajeErrores.push("Debe seleccionar el tipo de examen");
                if(!this.documento)this.mensajeErrores.push("Debe Digitar el documento del paciente");
                if(!this.nombres)this.mensajeErrores.push("Debe digitar los nombres y apellidos del examen");
                if(!this.archivo)this.mensajeErrores.push("Debe seleccionar el archivo que desea subir");
                if(this.mensajeErrores.length) this.errores=1;
                return this.errores;

            },
            limpiardatos(){
                this.codempsifab='',
                this.tipoarchi='',
                this.fecha='',
                this.tipoexam='',
                this.documento='',
                this.nombres='',
                this.archivo=null
            }

        },
            mounted() {
           this.espacio();
           this.listadoEmpresas();
        }
}
</script>
