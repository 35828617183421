<template>
    <main class="main">
        <!-- Breadcrumb -->
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="home">Escritorio</a></li>
            <li class="breadcrumb-item">Usuarios</li>
        </ol>
        <div class="container-fluid">
            <!-- Ejemplo de tabla Listado -->
            <div class="card">
                <div class="card-header">
                    <i class="fa fa-align-justify"></i> Usuarios
                    <button type="button" @click="abrirVentanaModal('agregar')" class="btn btn-secondary">
                        <i class="icon-plus"></i>&nbsp;Crear Nuevo Usuario
                    </button>
                </div>
                <div class="card-body">
                    <div class=" form-group row">
                        <div class="col-md-5">
                            <div class="input-group">
                                <select class="form-control col-md-4" v-model="criterio">
                                    <option value="useremp.nombre">Nombre</option>
                                    <option value="empresas.nombre">empresa</option>
                                     <option value="useremp.email">email</option>
                                </select>
                                <input type="text" v-model="buscar" @keyup.enter="listadoUsuarios(1,buscar,criterio)" class="form-control" placeholder="Texto a buscar">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button type="submit" @click="listadoUsuarios(1,buscar,criterio)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                        </div>
                        <div class="col-md-5">
                            <select class="form-control col-md-6" v-model="numpaginas" @change="listadoUsuarios(1,buscar,criterio,numpaginas)">  <!--  -->
                                            <option value="" selected>Registros por Página</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="25">25</option>
                                            <option value="30">30</option>
                                            <option value="35">35</option>
                                            <option value="40">40</option>
                                            <option value="45">45</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th >Empresa</th>
                                    <th>Usuario</th>
                                    <th>Nombre</th>
                                    <th>Cargo</th>
                                    <th>Estado</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="usuario in arrayUsuarios" :key="usuario.id">
                                    <td v-text="usuario.nombreemp"></td>
                                    <td v-text="usuario.email"></td>
                                    <td v-text="usuario.nombre"></td>
                                    <td v-text="usuario.cargo"></td>
                                    <td>
                                        <div v-if="usuario.estado=='A'">
                                            <span class="badge badge-success">Activo</span>
                                        </div>
                                            <div v-else>
                                            <span class="badge badge-danger">Inactivo</span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" @click="abrirVentanaModal('actualizar',usuario)" data-toggle="tooltip" data-placement="top" title="Editar los datos del Usuario"
                                            class="btn btn-info btn-sm">
                                            <i class="fas fa-edit"></i>
                                        </button> &nbsp;
                                        <template v-if="usuario.estado=='A'">
                                            <button type="button" class="btn btn-danger btn-sm"  @click="inactivarUsuario(usuario.id)" data-toggle="tooltip" data-placement="top" title="Inactivar Usuario">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </template>
                                        <template v-else>
                                            <button type="button" class="btn btn-success btn-sm"  @click="activarUsuario(usuario.id)" data-toggle="tooltip" data-placement="top" title="Activar Empresa">
                                                <i class="fas fa-check"></i>
                                            </button>
                                        </template>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <div hidden style="text-align:center; font-weight: bold;" id="aviso">
                            <p><strong><h3>No existen datos para mostrar</h3></strong></p>
                        </div>
                    </div>
                        <nav style="text-align:center;">
                            <ul class="pagination" style="display: inline-flex; margin:0; padding:0;"> <!--centrar botones de navegación -->
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                                </li>

                                <li class="page-item" v-for="page in pagesNumber" :key="page.number" v-bind:class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                                </li>

                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                                </li>
                            </ul>
                        </nav>
                </div>
            </div>
            <!-- Fin ejemplo de tabla Listado -->
        </div>
        <!--Inicio del modal agregar/actualizar-->
        <div class="modal fade" tabindex="-1" :class="{'mostrar' : vermodal}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
            <div class="modal-dialog-scrollable.modal-dialog modal-primary modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" v-text="tituloVentana"></h4>
                        <button type="button" class="close" @click="cerrarVentanaModal()" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                            <div class="form-group row">
                                <label class="col-md-2 form-control-label" for="text-input" style="color:white;">Email</label>
                                <div class="col-md-10">
                                    <input type="text" v-model="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 form-control-label" for="text-input" style="color:white;">Nombre</label>
                                <div class="col-md-4">
                                    <input type="text" v-model="nombre" class="form-control" placeholder="Nombre del Usuario">
                                </div>
                                 <label class="col-md-2 form-control-label" for="text-input" style="color:white;">Cargo</label>
                                <div class="col-md-4">
                                    <input type="text" v-model="cargo" class="form-control" placeholder="Cargo del Usuario">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 form-control-label" for="email-input" style="color:white;">Clave</label>
                                <div class="col-md-4">
                                    <input type="password" v-model="password" class="form-control" placeholder="Digite la clave">
                                </div>
                                <label class="col-md-2 form-control-label" for="email-input" style="color:white;">Repetir Clave</label>
                                <div class="col-md-4">
                                    <input type="password" v-model="password1" class="form-control" placeholder="Confirme la clave">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 form-control-label" for="text-input" style="color:white;">Empresa</label>
                                <div class="col-md-10">
                                    <div class="input-group" >
                                        <select class="form-control" v-model="idempresa" id="idempresa">
                                            <option value="0" disabled>Seleccione</option>
                                            <option v-for="empresa in arrayEmpresas" :key="empresa.id" :value="empresa.id" v-text="empresa.nombre"></option>

                                        </select>
                                    </div>
                               </div>
                            </div>
                             <div v-show="errores" class="form-group row div-error">
                                <div class="text-center text-error">
                                    <div v-for="error in mensajeErrores" :key="error" v-text="error">
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="cerrarVentanaModal()">Cerrar</button>
                            <button type="button" v-if="tipoAccion==1" class="btn btn-primary" @click="agregarUsuario()">Guardar</button>
                            <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizarEmpresa()">Actualizar</button>
                        </div>
                    </div>

                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!--Fin del modal-->
</main>
</template>

<script>
    export default {
        data(){
            return {
                idempresa : 0,    //para actualizar una empresa
                email: '',
                nombre :'',
                cargo :'',
                password :'',
                password1 :'',
                arrayUsuarios: [],
                arrayEmpresas:[],
                vermodal : 0,   //para controlar si se ve la ventana modal
                tituloVentana : '',
                tipoAccion : 0,  //para saber si actualiza o graba un nuevo registro
                errores : 0,    //para saber si hay errores en la validación
                mensajeErrores : [],
                pagination: {
                    'total':0,
                    'current_page':0,
                    'per_page':0,
                    'last_page':0,
                    'from':0,
                    'to':0
                },
                offset:3,  //para la paginación
                criterio: 'useremp.nombre',
                buscar: '',
                numpaginas: '',   //por defecto 6 registros por página
                variable: 'readonly'
            }
        },
        //las propiedades computadas son cacheadas basándose en sus dependencias. Una propiedad computada solo se reevaluará
        //cuando alguna de sus dependencias haya cambiado. Esto significa que mientras no haya cambiado, acceder reiteradamente
        //a la propiedad computada devolverá inmediatamente el resultado computado previamente sin tener que ejecutar la
        //función de nuevo. las propiedades computadas se usan generalmente para calculos complejos y renderizan más rápido
        //que las propiedades o métodos normales. ver artículo.
        //https://es-vuejs.github.io/vuejs.org/v2/guide/computed.html
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },
            pagesNumber: function(){
                if(!this.pagination.to){
                    return [];
                }
                var from = this.pagination.current_page - this.offset;
                if(from < 1){
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to= this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to){
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            }
        },
        methods: {
            mostraralerta(){
                alert('evento');  //para observar la ejecucuín de eventos
            },
             listadoUsuarios(page,textobuscar,condicion,pnumpaginas=5){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                var url='/usuarios?page='+ page + '&buscar=' + textobuscar + '&criterio=' + condicion + '&numpaginas=' + pnumpaginas;
                axios.get(url).then(function (response) {  //el verbo get trae los datos
                    clase.arrayUsuarios=response.data.usuariosemp.data;
                    clase.pagination=response.data.pagination;
                    if(clase.arrayUsuarios.length==0){
                        document.getElementById('aviso').removeAttribute('hidden');
                   }else{
                       //console.log('entro al else');
                       document.getElementById('aviso').setAttribute('hidden', 'hidden');
                   }
                    //console.log(response);
                })
                .catch(function (error) {
                    console.log('Error en Consulta');
                });
            },
            listadoEmpresas(){
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.get('/usuarios_empresa').then(function (response) {  //el verbo get trae los datos
                    clase.arrayEmpresas=response.data;
                    //console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            agregarUsuario(){
                if(this.validarUsuario()){
                    return; //SI HAY ERRORES RETORNA
                }

                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.post('/usuarios/agregar',{
                    'email' : this.email,
                    'nombre' : this.nombre,
                    'cargo' : this.cargo,
                    'password' : this.password
                }).then(function (response) {  //el verbo post envía los datos
                    //se ejecuta de forma satisfactoria
                    clase.agregarUsuarioEmpresa();
                    clase.cerrarVentanaModal();
                    clase.listadoUsuarios(1,'','nombre');
                })
                .catch(function (error) {
                     //alert ('entro aqui al error');
                    console.log(error);
                });
            },
            agregarUsuarioEmpresa(){
                axios.post('/usuarios/guardarEmpresa',{
                    'email' : this.email,
                    'idempresa' : this.idempresa
                }).then(function (response) {  //el verbo post envía los datos
                    //se ejecuta de forma satisfactoria
                    clase.cerrarVentanaModal();
                    clase.listadoUsuarios(1,'','nombre');
                })
                .catch(function (error) {
                     //alert ('entro aqui al error');
                    console.log(error);
                });
            },
            actualizarEmpresa(){
                if(this.validarUsuario()){
                    return; //SI HAY ERRORES RETORNA
                }
                let clase=this; //declaramos una variable para recibir toda la data del objeto response
                axios.put('/usuarios/actualizar',{
                    'email' : this.email,
                    'nombre' : this.nombre,
                    'cargo' : this.cargo,
                    'id' : this.idusuario,   //id de la empresa a actualizar. se envia para tomarlo en el request
                    'password' : this.password
                }).then(function (response) {  //el verbo post envía los datos
                    //se ejecuta de forma satisfactoria
                    clase.cerrarVentanaModal();
                    clase.listadoUsuarios(1,'','nombre');
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            cerrarVentanaModal(){
                this.vermodal=0;
                this.email='';
                this.nombre='';
                this.cargo ='';
                this.password ='';
                this.password1 ='';
                this.idempresa ='',
                this.tituloVentana='';
                this.mensajeErrores=[];
            },

            abrirVentanaModal(accion, data=[]){
                switch(accion){
                    case 'agregar':
                        this.vermodal=1;
                        this.codempsifab='';
                        this.nombre='';
                        this.contacto ='';
                        this.tituloVentana='Agregar Usuario';
                        this.tipoAccion=1;
                        document.getElementById('email').removeAttribute('readonly'); //quitar readonly
                        document.getElementById('idempresa').removeAttribute('disabled'); //quitar readonly
                        break;

                    case 'actualizar' :
                        this.vermodal=1;
                        this.tituloVentana='Actualizar Usuario';
                        this.tipoAccion=2;
                        this.idusuario=data.id;
                        this.email=data.email;
                        this.nombre=data.nombre;
                        this.cargo =data.cargo;
                        this.idempresa=data.idempresausu;
                        document.getElementById('email').setAttribute('readonly', 'readonly'); //colocar readonly
                        document.getElementById('idempresa').setAttribute('disabled', 'disabled'); //colocar readonly
                        break;
                }
            },
            inactivarUsuario(pidusuario){
                swal({
                title: 'Esta seguro de Inactivar este Usuario?',
                text: "",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar!',
                cancelButtonText: 'Cancelar',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
                }).then((result) => {
                if (result.value) {
                    let clase=this; //declaramos una variable para recibir toda la data del objeto response
                    axios.put('/usuarios/inactivar',{
                        'id' : pidusuario  //id del usuario a actualizar. ingresa como parámetro
                    }).then(function (response) {  //el verbo post envía los datos
                        //se ejecuta de forma satisfactoria
                        clase.listadoEmpresas();
                        clase.listadoUsuarios(1,clase.buscar,clase.criterio);
                        swal(
                        'Inactivado!',
                        'El usuario se ha inactivado correctamente',
                        'success'
                        )
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                } else if (
                    // Read more about handling dismissals
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    //no mostrar mensaje si el usuario cancela
                    /* swal(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                    ) */
                }
                })
            },
            activarUsuario(pidusuario){
                swal({
                title: 'Esta seguro de Activar este Usuario?',
                text: "",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar!',
                cancelButtonText: 'Cancelar',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false,
                reverseButtons: true
                }).then((result) => {
                if (result.value) {
                    let clase=this; //declaramos una variable para recibir toda la data del objeto response
                    axios.put('/usuarios/activar',{
                        'id' : pidusuario  //id de la empresa a actualizar. ingresa como parámetro
                    }).then(function (response) {  //el verbo post envía los datos
                        //se ejecuta de forma satisfactoria
                        clase.listadoEmpresas();
                        clase.listadoUsuarios(1,clase.buscar,clase.criterio);
                        swal(
                        'Activada!',
                        'El ha sido activado correctamente',
                        'success'
                        )
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                } else if (
                    // Read more about handling dismissals
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    //no mostrar mensaje si el usuario cancela
                    /* swal(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                    ) */
                }
                })
            },
            validarUsuario(){
                this.errores=0;
                this.mensajeErrores=[];
                if(!this.email)this.mensajeErrores.push("Debe Ingresar el Email del Usuario");
                if(!this.nombre)this.mensajeErrores.push("Debe Ingresar el Nombre del Usuario");
                if(!this.password)this.mensajeErrores.push("Debe Ingresar la contraseña del Usuario");
                if(!this.password1)this.mensajeErrores.push("Debe Confirmar la contraseña del usuario");
                if(this.password != this.password1)this.mensajeErrores.push("La contraseña no se confirmo correctamente");
                if(this.mensajeErrores.length) this.errores=1;
                return this.errores;

            },
            cambiarPagina(page,textobuscar,condicion){
                this.pagination.current_page = page;
                //envia la petición para visualizar la data de esa página
                this.listadoUsuarios(page,textobuscar,condicion);
            }
        },

        mounted() {
           this.listadoEmpresas();
           this.listadoUsuarios(1,this.buscar,this.criterio);
        }
    }
</script>

<style>
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
    }
    .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }
</style>
